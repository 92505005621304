export const KeyCode = (code) => {
    for (let key in KEY_CODE) {
        let index = KEY_CODE[key].indexOf(code.toString());
        if (index != -1) {
            return key;
        }
    }
}

export const KEYS = {
    'UP': 'UP', 'DOWN': 'DOWN', 'LEFT': 'LEFT',
    'RIGHT': 'RIGHT', 'SELECT': 'SELECT',
    'BACK': 'BACK', 'SEARCH': 'SEARCH',
    'PLAY': 'PLAY', 'PAUSE': 'PAUSE', 'PLAY_PAUSE': 'PLAY_PAUSE', 'STOP': 'STOP',
    'FAST_FWD': 'FAST_FWD', 'REWIND': 'REWIND',
    'LT': 'LT', 'RT': 'RT', 'LB': 'LB', 'RB': 'RB',
    '0': '0', '1': '1', '2': '2', '3': '3', '4': '4',
    '5': '5', '6': '6', '7': '7', '8': '8', '9': '9'
}

const keyMap = {
    HISENSE: {
        //navigation
        [KEYS['UP']]: ['38'],
        [KEYS['DOWN']]: ['40'],
        [KEYS['LEFT']]: ['37'],
        [KEYS['RIGHT']]: ['39'],
        [KEYS['SELECT']]: ['13'],
        [KEYS['BACK']]: ['8'],
        //player
        [KEYS['PLAY']]: ['415'],
        [KEYS['PAUSE']]: ['19'],
        [KEYS['STOP']]: ['413'],
        [KEYS['FAST_FWD']]: ['417'],
        [KEYS['REWIND']]: ['412'],
        //number
        [KEYS['0']]: ['48'],
        [KEYS['1']]: ['49'],
        [KEYS['2']]: ['50'],
        [KEYS['3']]: ['51'],
        [KEYS['4']]: ['52'],
        [KEYS['5']]: ['53'],
        [KEYS['6']]: ['54'],
        [KEYS['7']]: ['55'],
        [KEYS['8']]: ['56'],
        [KEYS['9']]: ['57']
    },
    WEBOS: {
        //navigation
        [KEYS['UP']]: ['38'],
        [KEYS['DOWN']]: ['40'],
        [KEYS['LEFT']]: ['37'],
        [KEYS['RIGHT']]: ['39'],
        [KEYS['SELECT']]: ['13'],
        [KEYS['BACK']]: ['461'],
        //player
        [KEYS['PLAY']]: ['415'],
        [KEYS['PAUSE']]: ['19'],
        [KEYS['STOP']]: ['413'],
        [KEYS['FAST_FWD']]: ['417'],
        [KEYS['REWIND']]: ['412'],
        [KEYS['NEXT']]: ['1546'],
        [KEYS['PREV']]: ['1545'],
        //number
        [KEYS['0']]: ['48'],
        [KEYS['1']]: ['49'],
        [KEYS['2']]: ['50'],
        [KEYS['3']]: ['51'],
        [KEYS['4']]: ['52'],
        [KEYS['5']]: ['53'],
        [KEYS['6']]: ['54'],
        [KEYS['7']]: ['55'],
        [KEYS['8']]: ['56'],
        [KEYS['9']]: ['57']
    },
    TIZEN: {
        //navigation
        [KEYS['UP']]: ['38'],
        [KEYS['DOWN']]: ['40'],
        [KEYS['LEFT']]: ['37'],
        [KEYS['RIGHT']]: ['39'],
        [KEYS['SELECT']]: ['13'],
        [KEYS['BACK']]: ['10009'],
        //player
        [KEYS['PLAY']]: ['415'],
        [KEYS['PAUSE']]: ['19'],
        [KEYS['PLAY_PAUSE']]: ['10252'],
        [KEYS['STOP']]: ['413'],
        [KEYS['FAST_FWD']]: ['417'],
        [KEYS['REWIND']]: ['412'],
        [KEYS['NEXT']]: ['10233'],
        [KEYS['PREV']]: ['10232'],
        //number
        [KEYS['0']]: ['48'],
        [KEYS['1']]: ['49'],
        [KEYS['2']]: ['50'],
        [KEYS['3']]: ['51'],
        [KEYS['4']]: ['52'],
        [KEYS['5']]: ['53'],
        [KEYS['6']]: ['54'],
        [KEYS['7']]: ['55'],
        [KEYS['8']]: ['56'],
        [KEYS['9']]: ['57']
    },
    XBOX: {
        //navigation
        [KEYS['UP']]: ['203', '211', '215'],
        [KEYS['DOWN']]: ['204', '212', '216'],
        [KEYS['LEFT']]: ['205', '214', '218', '201'],
        [KEYS['RIGHT']]: ['206', '213', '217', '202'],
        [KEYS['SELECT']]: ['195'],
        [KEYS['BACK']]: ['196'],
        [KEYS['LB']] : ['200'],
        [KEYS['RB']] : ['199'],
    },
    BROWSER: {
        [KEYS['LEFT']]: ['37'],
        [KEYS['UP']]: ['38'],
        [KEYS['RIGHT']]: ['39'],
        [KEYS['DOWN']]: ['40'],
        [KEYS['BACK']]: ['81'],
        [KEYS['SELECT']]: ['13'],
        [KEYS['SEARCH']]: ['89'],
        [KEYS['0']]: ['48'],
        [KEYS['1']]: ['49'],
        [KEYS['2']]: ['50'],
        [KEYS['3']]: ['51'],
        [KEYS['4']]: ['52'],
        [KEYS['5']]: ['53'],
        [KEYS['6']]: ['54'],
        [KEYS['7']]: ['55'],
        [KEYS['8']]: ['56'],
        [KEYS['9']]: ['57'],
        [KEYS['LB']]: ['65'], // A
        [KEYS['RB']]: ['68']  // D
    },
    STREAMA: {
        [KEYS['LEFT']]: ['37'],
        [KEYS['UP']]: ['38'],
        [KEYS['RIGHT']]: ['39'],
        [KEYS['DOWN']]: ['40'],
        [KEYS['BACK']]: ['8'],
        [KEYS['SELECT']]: ['13'],
        [KEYS['SEARCH']]: ['89'],
        [KEYS['0']]: ['48'],
        [KEYS['1']]: ['49'],
        [KEYS['2']]: ['50'],
        [KEYS['3']]: ['51'],
        [KEYS['4']]: ['52'],
        [KEYS['5']]: ['53'],
        [KEYS['6']]: ['54'],
        [KEYS['7']]: ['55'],
        [KEYS['8']]: ['56'],
        [KEYS['9']]: ['57'],
        [KEYS['LB']]: ['65'], // A
        [KEYS['RB']]: ['68']  // D
    },
    EXPLORA_ULTRA: {
        [KEYS['LEFT']]: ['37'],
        [KEYS['UP']]: ['38'],
        [KEYS['RIGHT']]: ['39'],
        [KEYS['DOWN']]: ['40'],
        [KEYS['BACK']]: ['8'],
        [KEYS['SELECT']]: ['13'],
        [KEYS['SEARCH']]: ['89'],
        [KEYS['0']]: ['48'],
        [KEYS['1']]: ['49'],
        [KEYS['2']]: ['50'],
        [KEYS['3']]: ['51'],
        [KEYS['4']]: ['52'],
        [KEYS['5']]: ['53'],
        [KEYS['6']]: ['54'],
        [KEYS['7']]: ['55'],
        [KEYS['8']]: ['56'],
        [KEYS['9']]: ['57'],
        [KEYS['LB']]: ['65'], // A
        [KEYS['RB']]: ['68'],  // D
        [KEYS['STOP']]: ['3'],
        [KEYS['PLAY_PAUSE']]: ['250']
    },
    ANDROID_TV: {
        [KEYS['LEFT']]: ['37'],
        [KEYS['UP']]: ['38'],
        [KEYS['RIGHT']]: ['39'],
        [KEYS['DOWN']]: ['40'],
        [KEYS['BACK']]: ['8'],
        [KEYS['SELECT']]: ['13'],
        [KEYS['SEARCH']]: ['89'],
        [KEYS['0']]: ['48'],
        [KEYS['1']]: ['49'],
        [KEYS['2']]: ['50'],
        [KEYS['3']]: ['51'],
        [KEYS['4']]: ['52'],
        [KEYS['5']]: ['53'],
        [KEYS['6']]: ['54'],
        [KEYS['7']]: ['55'],
        [KEYS['8']]: ['56'],
        [KEYS['9']]: ['57'],
        [KEYS['LB']]: ['65'], // A
        [KEYS['RB']]: ['68'],  // D
        [KEYS['STOP']]: ['3'],
        [KEYS['PLAY_PAUSE']]: ['250']
    }
}

const getKeyCodeByPlatform = () => {
    let keyCodes = keyMap[localStorage.PRODUCT_TYPE || 'BROWSER']
    let result = {}
    for (var key in KEYS) {
        keyCodes[key] && (result[key] = keyCodes[key])
    }
    return result
}

export const KEY_CODE = getKeyCodeByPlatform()