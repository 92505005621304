import React, { Component } from 'react'
import StreamaAccount from '@connected-video-web/dstv-frontend-components/dist/Components/Pages/StreamaSettings/StreamaAccount';
import { TvError } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import { Loader } from '@connected-video-web/dstv-frontend-components/dist/Components/Core';
import { connect } from 'react-redux';
import { ACCOUNT_INIT, ACCOUNT_MENU_FOCUS, ACCOUNT_MENU_DEVICE_FOCUS, DEVICE_ACTION_ONCLICK, REGISTER_DEVICE, REMOVE_DEVICE, REMOVE_SUBMENU_FOCUS_ONCLICK, BACK_TO_SIGNOUT, SIGNOUT_ACTION_ONCLICK, LOGOUT, STREAMAACCOUNT_ERROR_CLEAR } from "@connected-video-web/dstv-frontend-services/dist/actions";
class SettingsAccount extends Component {
    componentDidMount() {
        let payload;
        if (this.props.player.manageDeviceError) {
            payload = { 'manageDeviceError': true }
        }
        this.props.getInitialData(payload);
        if (this.props.flagr && this.props.flagr.is_debug_mode) {
            if (this.props.flagr.is_debug_mode.debug) {
                return this.flag = this.props.flagr.is_debug_mode.flag[0];
            }
        }
    }

    onClick = (data, clickedItem) => {
        if (data && data.id &&  (data.id === 'device' || data.id === 'stream' || data.id === 'signout' || data.id === 'about')) {
            if(this.props.account.data && this.props.account.data[1] && this.props.account.data[1].isTvFocus) {
                this.props.accountMenuFocus({data, type: ACCOUNT_MENU_FOCUS,  move: 'CLICK', key: 'streamaAccount'})
            } else {
                if ((this.props.account.data && this.props.account.data[0]) && (!this.props.account.data[2] || (this.props.account.data[2] && this.props.account.data[2].length === 0) )) {
                    this.props.accountMenuFocus({data, type: data.id === 'device' ? ACCOUNT_MENU_DEVICE_FOCUS : ACCOUNT_MENU_FOCUS,  move: 'CLICK', key: 'streamaAccount'})
                }
            }
        } else {
            if (clickedItem === 'register_device_confirm') {
                data.isTvFocus = false
                data.isActive = false
                this.props.registerDevice({item: data, key: 'streamaAccount'})
            } else if (clickedItem === 'remove_device_confirm') {
                data.isTvFocus = false
                data.isActive = false
                this.props.removeDevice({item: data, key: 'streamaAccount'})
            } else if (data && data.renderType === 'device') {
                data.isTvFocus = false
                data.isActive = false
                this.props.deviceActionConfirm({item: data, clickedItem: clickedItem, key: 'streamaAccount'})
            } else if (clickedItem === 'set_quality') {
                this.props.setVideoQuality({currentItem: data, renderType: 'stream', clickedItem: clickedItem})
            } else if (clickedItem === 'sign_out') {
                this.props.signOutActionConfirm({item: data, clickedItem: clickedItem, key: 'streamaAccount'})
            } else if (clickedItem === 'back_to_signout') {
                this.props.backToSignout({item: data, clickedItem: clickedItem, key: 'streamaAccount'})
            } else if (clickedItem === 'signout_now') {
                this.props.logOut({item: data, clickedItem: clickedItem, key: 'streamaAccount'})
            } else if (clickedItem === 'launch_landing_page') {
                window.location.href = this.flag.flagValue;
            }
        }
    }

    onErrorActionSelected = (e) => {
        if(this.props.account && this.props.account.data && this.props.account.data[0] && this.props.account.data[0].isTvFocus) {
            let data = this.props.account.data[0].items[1]
            this.props.accountMenuFocus({data, type: ACCOUNT_MENU_DEVICE_FOCUS,  move: 'CLICK', key: 'streamaAccount'})
        } else if (this.props.account && this.props.account.data && this.props.account.data[2] && this.props.account.data[2].items && this.props.account.data[2].items[1] && this.props.account.data[2].items[1].id === 'registerDevice') {
            this.props.registerDevice({item: this.props.account.data[2], key: 'streamaAccount'})
        } else if (this.props.account && this.props.account.data && this.props.account.data[2] && this.props.account.data[2].items && this.props.account.data[2].items[1] && this.props.account.data[2].items[1].id === 'removeDevice') {
            this.props.removeDevice({item: this.props.account.data[2], key: 'streamaAccount'})
        }
    }

    onErrorBackSelected = (e) => {
        this.props.clearSettingError()
    }

    render() {
        let error;
        if (this.props.account && this.props.account.error && this.props.account.error.isVisible) {
            error = <TvError {...this.props.account.error} onSelected={[this.onErrorActionSelected, this.onErrorBackSelected]}/>
        }
        return (
            error ? error : <div><Loader fromSmartTv={true} isLoading={this.props.account.isLoading} /> <StreamaAccount data={this.props.account.data} onClick={this.onClick} isFocussed={this.props.account && this.props.account.isTvFocus} flag={this.flag} /></div>
        )
    }

}

const mapStateToProps = state => {
    return {
        account: state.streamaAccount,
        player: state.player,
        flagr: state.flagr,
    }
};
/**
 * 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    getInitialData: (payload) => dispatch({ type: ACCOUNT_INIT, payload }),
    accountMenuFocus: (payload) => dispatch({ type: payload.type, payload}),
    deviceActionConfirm: (payload) => dispatch({ type: DEVICE_ACTION_ONCLICK, payload}),
    registerDevice: (payload) => dispatch({type: REGISTER_DEVICE, payload}),
    removeDevice: (payload) => dispatch({type: REMOVE_DEVICE, payload}),
    removeSubmenuFocusOnClick: (payload) => dispatch({ type: REMOVE_SUBMENU_FOCUS_ONCLICK, payload}),
    setVideoQuality: (payload) => dispatch({type: 'SET_VIDEO_QUALITY', payload}),
    signOutActionConfirm: (payload) => dispatch({ type: SIGNOUT_ACTION_ONCLICK, payload}),
    backToSignout: (payload) => dispatch({ type: BACK_TO_SIGNOUT, payload}),
    logOut: (payload) => dispatch({type: LOGOUT, payload}),
    clearSettingError: () => dispatch({type: 'STREAMAACCOUNT_ERROR_CLEAR'})
})
export default connect(mapStateToProps, mapDispatchToProps)(SettingsAccount);
