import React, { Component } from 'react'
import styles from '../../base-styles/playerui.scss';
import { connect } from 'react-redux'
import StreamHelper, { licenseNetworkHelper } from '@connected-video-web/dstv-frontend-services/dist/Utils/streamHelper';
import { exoPlayerEnabled } from '@connected-video-web/dstv-frontend-services/dist/Utils/flagrHelper';
import { getDeviceName, useNativePlayer } from '@connected-video-web/dstv-frontend-services/dist/Utils/platform';
import { KeyCode } from '../../keyCodeMapping';
import { ENTITLEMENT, STOP_SEEK, SAVE_BOOKMARK, GET_CONCURRENCY_STATUS, PLAYER_CALLBACK_ISPLAYING, PLAYER_CALLBACK_PLAY, PLAYER_ERROR, PLAYER_CALLBACK_PAUSE, PLAYER_CALLBACK_RESUMED, ANDROID_PLAYER_ERROR, CHANNELS_REMOTECONFIG_MESSAGE } from '@connected-video-web/dstv-frontend-services/dist/actions'
import LivePlayerCtrl from './LivePlayerCtrl';
import VodPlayerCtrl from './VodPlayerCtrl';
import PlayerInactiveDlg from './PlayerInactiveDlg';
import { Loader } from '@connected-video-web/dstv-frontend-components/dist/Components/Core';
import { GLOBALS } from '../../globals';
import { TvError } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import SessionHelper from '@connected-video-web/dstv-frontend-services/dist/Utils/sessionHelper'
import { findActiveEpisode, getAssetTag, getContainerStates, getCurrentTime } from './PlayerHelper'
import { internetIsConnected } from '@connected-video-web/dstv-frontend-services/dist/Utils/networkHelper';
import { networkPayload } from '../helper'
import pulseAdsPreview from '../../Images/transparent.png';
import TrackingHelper from "@connected-video-web/dstv-frontend-services/dist/Utils/trackingHelper";
import { postAPIErrorToSegment } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import { talkToChannel, getCurrentResolution } from '@connected-video-web/streama-system-manager-service/dist/services/talkToChannel';
import { StorageKey } from '@connected-video-web/dstv-frontend-services/dist/constants/storageKey';
import { secureLocalStorage } from '@connected-video-web/dstv-frontend-middleware/dist/Utils/secureStorage';
import { irdetoCtrEnabled, autoPlayEnabled, shouldEnableAutoPlayToggle, playerPopEnabledConfig, playerPopEnabled, wmeEnabled, playerDebugLogEnabled, playreadyVersion, bookmarkIntervalEnabled, getBookmarkValue } from '@connected-video-web/dstv-frontend-services/dist/Utils/flagrHelper';
import { GET_CDN_SHORT_TOKEN, CLEAR_CDN_TOKEN, CDN_SHORT_TOKEN_ERROR } from '@connected-video-web/dstv-frontend-services/dist/constants/actionTypes';
import { DEFAULT_RETRY_COUNT } from '@connected-video-web/dstv-frontend-services/dist/constants/constants';

const semver = require('semver')
var ANDROID_JAVASCRIPT_INTERFACE = "android_javascript_interface"
let productType = localStorage.getItem('PRODUCT_TYPE');


class PlayerContainer extends Component {
    constructor(props) {
        super(props);
        this.player = ''
        this.loader = ''
        this.backdrop = ''
        this.playerInactiveScreen = null
        this.callEntitlement = true
        this.handlePlayRequest = false
        this.callConcurrency = true
        this.concurrencyTimer = null
        this.playerInactiveTimer = null
        this.resetTimeoutTimer = null
        this.callTimerOnInitialise = true;
        this.pauseTime = 0
        this.currentDuration = 0
        this.lastHeartbeat = 0
        window.count = 0
        this.isExoPlayer = false,
            this.state = getContainerStates(),
            this.bookmarkTimer = null
        this.deviceResolution = null
    }

    getVideoQuality = () => {
        let chosenQuality = 6000000;
        if (localStorage.getItem('PRODUCT_TYPE') === 'STREAMA') {
            localStorage.setItem("QUALITY_STORAGE_BITRATE", Math.min(chosenQuality, StreamHelper.MatchScreenCapabilities(this.deviceResolution)))
            return Math.min(chosenQuality, StreamHelper.MatchScreenCapabilities(this.deviceResolution));
        } else {
            return chosenQuality;
        }
    }

    getDeviceResolution = async () => {
        try {
            let deviceResolutionResponse = await getCurrentResolution();
            this.deviceResolution = deviceResolutionResponse.result.resolution.split("p")[0];
        } catch (e) { }
    }

    componentDidMount() {
        //TODO: Remove Explora check after RRM deprecation
        if ((localStorage.getItem('PRODUCT_TYPE') === 'EXPLORA_ULTRA') || (irdetoCtrEnabled() && ((localStorage.getItem('PRODUCT_TYPE') === 'HISENSE' && playreadyVersion()) || localStorage.getItem('PRODUCT_TYPE') !== 'HISENSE'))) {
            let video = document.getElementById('player');
            let player = new shaka.Player(video);
            window.player = player;
            shaka.polyfill.installAll();
        }
        window.addEventListener('keydown', this.getRemoteKeys);

        //Android Native Interface Events
        window.addEventListener('PlayerClosedEvent', this.androidStopPlayer);
        window.addEventListener('PlayerErrorEvent', this.androidPlayerError);

        if (localStorage.getItem('PRODUCT_TYPE') === 'TIZEN' || localStorage.getItem('PRODUCT_TYPE') === 'XBOX') {
            let self = this
            // app paused and resume event handling
            if ('hidden' in document) {
                document.addEventListener('visibilitychange',
                    self.handleVisibilityChange)
            } else if ('webkitHidden' in document) {
                document.addEventListener('webkitvisibilitychange',
                    self.handleVisibilityChange)
            }
        }

        //Check device resolution on Streama to avoid 4K blank video
        if (localStorage.getItem('PRODUCT_TYPE') === 'STREAMA') {
            this.getDeviceResolution();
        }
    }

    getRemoteKeys = (e) => {
        let path = this.props.location.pathname.split('/')
        if (this.state.showPlayer || (path[2] === 'play' || path[4] === 'play')) {
            if (KeyCode(e.keyCode) === 'SELECT') {
                this.trySkipAd();
            }
        }
    }

    trySkipAd = () => {
        if (this.state.canSkip) {
            window.adPlayer.skipButtonClicked();
            this.setState({ canSkip: false });
        }
    }
    handleVisibilityChange = () => {
        try {
            if (document.visibilityState === 'hidden') {
                this.player.pause()
            }
            else if (document.visibilityState === 'visible' && this.props.player.playbackType !== 'live') {
                this.player.resume ? this.player.resume() : this.player.play()
            }
        }
        catch (ex) {
            console.error("API ERROR")
            switch (localStorage.getItem('PRODUCT_TYPE')) {
                case 'TIZEN':
                    tizen.application.getCurrentApplication().exit();
                    break;
                case 'WEBOS':
                    webOS.platformBack()
                case "ANDROID_TV":
                case 'EXPLORA_ULTRA':
                    window.location.replace("about:blank");
                default:
                    window.close();
            }
        }
    }

    //Android Native Interface Functions
    androidStartPlayer = (player) => {
        let videoQuality = this.getVideoQuality();
        if (player.channel && player.playbackType === 'live') {
            window[ANDROID_JAVASCRIPT_INTERFACE].onPlayLiveTV(player.channel.channelId, parseInt(videoQuality));
            window.backDisabled = true;
        } else {
            if (player.video && player.video.video && player.video.video.items.length &&
                player.video.video.items[0] && player.video.video.items[0].details && player.video.video.items[0].details.id
            ) {
                window[ANDROID_JAVASCRIPT_INTERFACE].onAutoPlayToggle(shouldEnableAutoPlayToggle());
                if (semver.satisfies(secureLocalStorage.getItem("app_ver"), '>=2.5.0')) {
                    window[ANDROID_JAVASCRIPT_INTERFACE].onPlayVod(player.video.video.items[0].details.id, parseInt(videoQuality), this.props.showpage.isFromTryThis ? this.props.showpage.isFromTryThis : false);
                } else {
                    window[ANDROID_JAVASCRIPT_INTERFACE].onPlayVod(player.video.video.items[0].details.id, parseInt(videoQuality));
                }
                window.backDisabled = true;
            } else {
                console.log("Asset details are missing.");
            }
        }
    }

    androidStopPlayer = () => {
        if (!this.props.player.error) {
            window.count++
            if (window.count > 1) {
                this.stopPlayer();
                window.count = 0
            }
            window.backDisabled = false;
        }
    }

    androidPlayerError = (e) => {
        this.props.androidPlayerError({
            type: e.detail.type,
            mainHeader: e.detail.title && e.detail.title,
            subHeader: e.detail.description && e.detail.description,
            error:
                networkPayload({
                    callback: () => { this.androidStartPlayer(this.props.player) },
                })
        });
        window.backDisabled = false;
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.player.isOpen && !this.state.isLoading)
            this.setState({ ...this.state, isLoading: true })
        else if (!nextProps.player.isOpen && this.state.isLoading)
            this.hidePlayer()

        if ((nextProps.player.isOpen && !this.props.player.isOpen)) {
            this.setState({ showBackdrop: true });
        } else if ((!nextProps.player.isOpen && this.props.player.isOpen)) {
            this.handleRetryBackNavigation(nextProps)
        } else if (!this.props.player.shouldOnlySwitchStream && nextProps.player.shouldOnlySwitchStream && !this.handlePlayRequest) {
            this.handlePlayRequest = true
            this.handleNewPlayRequest()
        } else {
            if (this.state.showPlayer && !nextProps.player.bookmarkCalled && !nextProps.player.error) {
                if (
                    this.isCdnTokenPresent(nextProps) ||
                    !this.isCdnTokenRequired(nextProps) ||
                    !this.isWatermarkingSuportedDevice()
                ) {
                    this.checkEntitlementAndConcurrency(nextProps);
                }
            } else if (this.state.showPlayer && nextProps.player.error)
                this.checkError(nextProps)
        }
        if (nextProps && nextProps.player && nextProps.player.error && (nextProps.player.serverError)) {
            postAPIErrorToSegment(nextProps.player.error, { errorMessage: nextProps.player.serverError })
        }
    }

    isCdnTokenRequired(nextProps) {
        let isTokenRequired = false;
        if (
            nextProps.player.playbackType === "live" && wmeEnabled()
        ) {
            isTokenRequired = true;
        }
        return isTokenRequired;
    }

    isCdnTokenPresent(nextProps) {
        const cdnAccessToken =
            nextProps.cdnToken && nextProps.cdnToken.accessToken;

        const isCdnAccessTokenError =
            nextProps.cdnToken.retryCount === nextProps.cdnToken.count &&
            nextProps.cdnToken.error === CDN_SHORT_TOKEN_ERROR;
        return this.isCdnTokenRequired(nextProps) && !!(cdnAccessToken || isCdnAccessTokenError);
    }

    componentWillUnmount() {
        // Clear loader spinner and entitlement flags
        this.loader = null
        this.backdrop = null
        this.callEntitlement = true
        this.callConcurrency = true
        clearInterval(this.concurrencyTimer)
        clearInterval(this.bookmarkTimer)
        clearTimeout(this.playerTimeout);
        TrackingHelper.ClearHeartbeatTime();
    }

    showPlayer = () => {
        this.setState({ ...this.state, showPlayer: true, fromError: false, isLoading: true })
    }

    hidePlayer = () => {
        this.props.showpage.isLoading = undefined;
        this.setState({ ...this.state, showPlayer: false, fromError: false, isLoading: false })
    }

    // handle new play request - previous, next
    handleNewPlayRequest = () => {
        let self = this
        this.setState({ ...this.state, playerLoaded: false }, () => {
            if (self.player)
                self.clearPlayerEvents()

            self.callEntitlement = true
            self.setState({ ...self.state, currentDuration: 0, mediaDuration: 0, playbackStarted: false, playing: false, isLoading: true, showPlayer: false })
        })
    }

    // Retry and back from Error Notifications
    handleRetryBackNavigation = (nextProps) => {
        let self = this;
        this.setState({ ...this.state, playerLoaded: false }, () => {
            if (self.player) {
                self.saveBookmark(this.currentDuration)
                self.clearPlayerEvents()
            }
            self.setState({ ...self.state, currentDuration: 0, mediaDuration: 0, playbackStarted: false, playing: false, isLoading: false, showPlayer: false })
            const { playbackStarted, playbackType, trackingPlayId } = this.props.player
            if (playbackType !== "error")
                localStorage.PRODUCT_TYPE === "STREAMA" && talkToChannel({ payload: { playbackStarted, playbackType, trackingPlayId } });
            TrackingHelper.ClearHeartbeatTime();
            self.callEntitlement = true
            self.callConcurrency = true
            self.handlePlayRequest = false
            window.playerRetry = false
            if (self.state.fromError)
                self.manageErrorRouting(nextProps)
        })
    }

    // Routing on error
    manageErrorRouting = (nextProps) => {
        if (this.props.player.manageDeviceError) {
            this.props.closePlayer(nextProps, this.props.player.manageDeviceError)
            this.props.history.push("/settings")
        } else {
            this.resetCurrentDuration();
            this.props.closePlayer(nextProps, false)
            this.props.history.goBack()
        }
    }
    channelsMessagePayload = () => {
        if (playerPopEnabled()) {
            let playerConfig = playerPopEnabledConfig()
            if (this.props.player.channel)
                for (let i = 0; i < playerConfig.streaming.channels.length; i++)
                    if (this.props.player.channel.channelNumber === playerConfig.streaming.channels[i])
                        return true
            return false
        }
        return false
    }

    // Entitlement and Concurrency check
    checkEntitlementAndConcurrency = (nextProps) => {
        let entitlementString = secureLocalStorage.getItem("playback-entitlement");
        entitlementString = JSON.parse(entitlementString)
        let entitlementSettings = nextProps.entitlement && nextProps.entitlement.irdetoSession && new Date(nextProps.entitlement.irdetoSession.sessionExpiryDateTime) > new Date() ? nextProps.entitlement.irdetoSession : null;
        if (window.isAndroidTV && this.isExoPlayer) {
            this.updatePlayer(nextProps);
        } else {
            if (irdetoCtrEnabled()) {
                entitlementSettings = entitlementString && entitlementString.irdetoSession && entitlementString.irdetoSession.irdetoCtrEnabled && new Date(entitlementString.irdetoSession.sessionExpiryDateTime) > new Date() ? entitlementString.irdetoSession : null;
                if (this.props.player.isLoading) {
                    if (this.callEntitlement) {
                        this.setState({ ...this.state, isLoading: true, timeToShowMessage: 60 })
                        this.callEntitlementAPI()
                    } else if (!entitlementSettings && !this.callEntitlement) {
                        this.checkError(nextProps)
                    } else if (entitlementSettings) {
                        this.updatePlayer(nextProps);
                    }
                }
            } else {
                let concurrentSession = nextProps.concurrency && nextProps.concurrency.isPlaybackEnabled ? nextProps.concurrency.isPlaybackEnabled : false;
                if (this.props.player.isLoading) {
                    if (!entitlementSettings && this.callEntitlement) {
                        this.callEntitlementAPI()
                    } else if (!entitlementSettings && !this.callEntitlement) {
                        this.checkError(nextProps)
                    } else if (!concurrentSession && this.callConcurrency) {
                        this.checkConcurrentSession()
                    } else if (!concurrentSession && !this.callConcurrency) {
                        this.checkError(nextProps)
                    } else if (entitlementSettings && concurrentSession) {
                        this.updatePlayer(nextProps);
                    }
                }
            }
        }
    }

    // Entitlement API call
    callEntitlementAPI = () => {
        //Look for device ID being passed from the Android TV wrapper app
        if (window.isAndroidTV) {
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams && (urlParams.has('device_id'))) {
                let androidUUID = urlParams.get('device_id');
                localStorage.setItem(GLOBALS.DEVICE_ID_STORAGE_KEY, SessionHelper.GenerateDeviceId(androidUUID));
            }
        }

        if (localStorage.getItem(GLOBALS.DEVICE_ID_STORAGE_KEY)) {
            GLOBALS.DEVICE_ID = localStorage.getItem(GLOBALS.DEVICE_ID_STORAGE_KEY)
        } else {
            GLOBALS.DEVICE_ID = SessionHelper.getRandomDeviceId()
            localStorage.setItem(GLOBALS.DEVICE_ID_STORAGE_KEY, GLOBALS.DEVICE_ID)
        }

        if (internetIsConnected()) {
            this.callEntitlement = false
            this.props.DoEntitlement({ 'deviceId': localStorage.getItem(GLOBALS.DEVICE_ID_STORAGE_KEY) });
        } else {
            this.handleNetworkError()
        }
    }

    // Concurrency API call
    checkConcurrentSession = () => {
        if (internetIsConnected()) {
            this.callConcurrency = false
            let assetTag = getAssetTag(this.props.player)
            this.props.checkConcurreny({ 'deviceId': GLOBALS.DEVICE_ID, 'deviceName': getDeviceName(), 'assetTag': assetTag })
        } else {
            this.handleNetworkError()
        }
    }

    saveBookmark = (currentTime) => {
        if (this.props.player.actionPayload && this.props.player.actionPayload.type === "PLAY_EPISODE" && currentTime > 0) {
            return this.props.saveBookmark({ genref: this.props.player.actionPayload.episode.items[0].details.genRef, timeInSeconds: window.localStorage.removeItem(StorageKey.SK_STORED_BOOKMARK) > 0 ? window.localStorage.removeItem(StorageKey.SK_STORED_BOOKMARK) : currentTime })
        }
        return null;
    }

    clearPlayerEvents = () => {
        if (this.player.removeEventCallback) {
            this.player.removeEventCallback(this, this._onPlayerEvent)
        }
        this.destroyPlayer()
        this.hidePlayer()
        clearInterval(this.concurrencyTimer)
    }

    destroyPlayer = () => {
        this.setState({ ...this.state, playbackStarted: false })
        try {
            this.callEntitlement = true
            if (window.refreshTimer) {
                clearInterval(window.refreshTimer);
                window.refreshTimer = null;
            }
            if (window.timerError) {
                clearTimeout(window.timerError);
                window.timerError = null;
            }
            if (this.bookmarkTimer) {
                clearInterval(this.bookmarkTimer)
                this.bookmarkTimer = null
            }
            if (this.player.getState) {
                switch (this.player.getState()) {
                    case 'EMPTY':
                    case 'STOPPED':
                    case 'ERROR':
                        break
                    default:
                        try {
                            this.player.stop()
                        } catch (e) {
                            console.error('stop error:-' + e)
                        }
                        console.log('Player stop')
                        break
                }

            }
            if (this.player.reset) {
                this.player.reset()
            }
            else {
                if (window.player && window.player.unload) {
                    this.player.removeEventListener("playing", this.playingEvent, false)
                    this.player.removeEventListener("pause", this.pauseEvent, false)
                    this.player.removeEventListener("timeupdate", this.playerTimeUpdateEvent, false)
                    this.player.removeEventListener("canplay", this.canplayEvent, false)
                    this.player.removeEventListener("ended", this.gobackfromPlayer, false)
                    window.player.removeEventListener("error", this.errorEvent, false)
                    window.player.getNetworkingEngine().unregisterRequestFilter();
                    window.player.getNetworkingEngine().clearAllRequestFilters();
                    window.player.detach()
                    window.player.unload()
                }
                else {
                    this.player.unload()
                }
            }
        } catch (e) {
            console.error(e, "player destroy")
        }

        try {
            if (window.adPlayer) {
                window.adPlayer.stopSession();
                window.adPlayer.getContainer().style.display = 'none';
            }
        } catch (e) {
            console.error(e, "ad player destroy error")
        }
    }

    // Error popups
    checkError = (nextProps) => {
        if (this.player) {
            if (nextProps.player.error && nextProps.player.error !== null) {
                this.clearPlayerEvents()
            }
        }
        if (this.playerInactiveTimer !== null && this.props.player.channel && this.props.player.playbackType === 'live') {
            this.clearPlayerInactiveTimer()
        }
        if (nextProps.player && nextProps.player.error)
            this.setState({ ...this.state, playbackStarted: false, fromError: true, playerLoaded: false })
        else
            this.setState({ ...this.state, playbackStarted: false, playerLoaded: false })
    }

    // Player Controls - Live/ VOD
    getPlayerUI = () => {
        let adContainer = document.getElementById("adContainer");
        if (!adContainer && window.adPlayer) {
            adContainer = window.adPlayer.getContainer();
        }

        if (this.props.player.isLoading && this.props.player.isOpen) {
            if (this.props.player.isLoading && this.props.player.channel && this.props.player.playbackType === 'live')
                return <LivePlayerCtrl {...this.props} {...this.state} clearPlayerInactiveTimer={this.clearPlayerInactiveTimer} resetPlayerInactiveTimer={this.resetPlayerInactiveTimer} destroyPlayer={this.destroyPlayer} stopPlayer={this.stopPlayer} notifySwitchChannel={this.notifySwitchChannel} />
            return <VodPlayerCtrl {...this.props} {...this.state} updatePlaybackState={this.updatePlaybackState} updateCurrentDuration={this.updateCurrentDuration} hidePlayer={this.hidePlayer} stopPlayer={this.stopPlayer} destroyPlayer={this.destroyPlayer} resetVodPlaybackStatesforAutoPlay={this.resetVodPlaybackStatesforAutoPlay} saveBookmark={this.saveBookmark} />
        }
        return null
    }

    notifySwitchChannel = () => {
        this.setState({ playbackStarted: false });
    }

    handleNetworkError = () => {
        if (this.player)
            this.clearPlayerEvents()

        this.setState({ ...this.state, showPlayer: false, playbackStarted: false, fromError: true, playerLoaded: false })
        this.props.throwNetworkError(
            networkPayload({
                callback: () => {
                    if (window.isAndroidTV && this.isExoPlayer) {
                        this.androidStartPlayer(this.props.player)
                    } else {
                        this.showPlayer();
                        this.props.playVideo(this.props.player.actionPayload || this.props.player.channel)
                    }
                }
            }))
    }

    updatePlayer = (nextProps) => {
        if (this.props.player.isOpen && !nextProps.player.isOpen) {
            this.destroyPlayer()
            this.hidePlayer()
        }

        if (this.state.playerLoaded)
            this.handlePlayerActions(nextProps)
        else {
            this.initialisePlayer(nextProps)
        }
    }

    // Timer clear
    clearPlayerInactiveTimer = () => {
        this.setState({ ...this.state, inActiveHours: 0 });
        if (this.playerInactiveTimer !== null)
            clearInterval(this.playerInactiveTimer)
    }

    managePlayerInactiveHours = () => {
        self = this
        this.playerInactiveTimer = setInterval(function () {
            if (self.props.player && self.props.player.error && self.props.player.error.isVisible) { // Check error triggered during popup
                self.setState({ ...self.state, inActiveHours: 0 })
                self.clearPlayerInactiveTimer()
                if (self.props.player.idleScreenDialogue)
                    self.props.clearIdleScreenDlg()
            } else if (!self.props.player.idleScreenDialogue) {
                if (self.state.inActiveHours >= GLOBALS.SCREEN_INACTIVE_LIMIT - 1) {
                    self.props.showInactiveScreen()  // Show Inactive screen
                } else {
                    self.setState({ ...self.state, inActiveHours: self.state.inActiveHours + 1 })
                }
            }
        }, GLOBALS.SCREEN_INACTIVE_INTERVAL)
    }

    // Reset player inactive timer
    resetPlayerInactiveTimer = () => {
        let self = this
        this.clearPlayerInactiveTimer()
        clearTimeout(this.resetTimeoutTimer)
        this.resetTimeoutTimer = setTimeout(function () {
            self.setState({ ...self.state, inActiveHours: 0 }, () => {
                self.managePlayerInactiveHours()
            });
        }, 1000) //delay to avoid asynch calls
    }

    // Play, Pause, Stop
    handlePlayerActions = (nextProps) => {

        let adContainer = document.getElementById("adContainer");
        if (!adContainer && window.adPlayer) {
            adContainer = window.adPlayer.getContainer();
        }

        if (this.props.player.playbackStarted === false && nextProps.player.playbackStarted) {
            if (nextProps.player.playbackType === 'live' && this.pauseTime > 0) {
                this.resumeLivePlayerCurrentTime()
            } else {
                if ((!nextProps.player.playFrom || nextProps.player.playFrom === -1) && this.player.resume) {
                    if (this.player.getState() !== 'EMPTY' && this.player.getState() !== 'BUFFERING') {
                        this.player.resume()
                    }
                } else if (nextProps.player.playFrom > -1 && this.player.playFrom) {
                    if (this.player.getState() !== 'EMPTY' && this.player.getState() !== 'BUFFERING') {
                        this.player.playFrom(nextProps.player.playFrom)
                        this.props.stopSeek()
                    }
                }
                else {
                    if (this.player.getSource && this.player.getSource())
                        this.player.play()
                    else if (this.player.play)
                        this.player.play()
                }
                this.props.playerResumeEvent(this.currentDuration)
            }
        } else if (this.props.player.playbackStarted && !nextProps.player.playbackStarted && this.state.playbackStarted) {
            if (this.player.getState) {
                if (this.player.getState() !== 'EMPTY' && this.player.getState() !== 'BUFFERING') {
                    this.player.pause()
                }
            } else {
                if (this.player.getSource && this.player.getSource())
                    this.player.pause()
                else if (this.player.pause) {
                    this.setState({ ...this.state, timeToShowMessage: 60 })
                    this.player.pause()
                }
            }
            if (nextProps.player.playbackType === 'live')
                this.pauseTime = getCurrentTime()
            if (nextProps.player.playbackType !== 'live')
                this.props.playerPauseEvent(this.currentDuration)

        } else if (nextProps.player.playFrom > -1 && nextProps.player.seeking) {
            if (this.player.getState) {
                if (this.player.getState() !== 'EMPTY' && this.player.getState() !== 'BUFFERING') {
                    if (this.player.playFrom) {
                        this.player.playFrom(nextProps.player.playFrom)
                    }
                }
            } else {
                this.player.seek ? this.player.seek(nextProps.player.playFrom) : this.player.currentTime = nextProps.player.playFrom;
            }
            this.props.stopSeek()
        }
    }

    resumeLivePlayerCurrentTime = () => {
        let playRequestTime = Math.round((new Date()).getTime() / 1000)
        let diffTime = playRequestTime - this.currentDuration
        var playTime = diffTime + this.currentDuration
        if (this.player.playFrom) {
            if (this.player.getState() !== 'EMPTY' && this.player.getState() !== 'BUFFERING') {
                this.player.playFrom(playTime)
            }
        } else {
            this.setState({ ...this.state, timeToShowMessage: 60 })
            this.player.play()
        }
        this.pauseTime = 0
    }

    // check entitlement data and prepare Player object - call wintergarten
    initialisePlayer = (nextProps) => {
        this.isExoPlayer = semver.satisfies(secureLocalStorage.getItem("app_ver"), '>=2.4.5') && exoPlayerEnabled();
        if (nextProps.player && nextProps.player.playbackType) {
            if (nextProps.player.channel && nextProps.player.playbackType === 'live') {
                this.resetPlayerInactiveTimer() // Check for Inactive - during playback starts
            }
            if (window.isAndroidTV && this.isExoPlayer) {
                if (window[ANDROID_JAVASCRIPT_INTERFACE]) {
                    this.androidStartPlayer(nextProps.player)
                } else {
                    console.error("Android TV native app interface not present.");
                }
                this.setState({ ...this.state, isLoading: false, playerLoaded: true });
            } else {
                let entitlementSettings = nextProps.entitlement && nextProps.entitlement.irdetoSession && new Date(nextProps.entitlement.irdetoSession.sessionExpiryDateTime) > new Date() ? nextProps.entitlement.irdetoSession : null;
                if ((this.props.player.shouldOnlySwitchStream && !nextProps.player.shouldOnlySwitchStream) || (nextProps.player.isLoading && nextProps.player.playbackType)) {
                    if (entitlementSettings) {
                        this.callWinterGarten(nextProps, entitlementSettings)
                    } else {
                        console.log("Player not entitled.");
                    }
                } else if (nextProps.player.isOpen && !nextProps.player.isLoading && !window.isAndroidTV) {
                    if (Object.keys(this.props.entitlement).length) {
                        this.showPlayer()
                    }
                }
            }
        }
    }

    timeUpdateEvent = () => {
        let videoElement = this.player.getVideoElement()
        if (!this.state.playbackStarted || this.state.isLoading) {
            this.setState({ ...self.state, playbackStarted: true, isLoading: false })
        }
        if (this.state.mediaDuration !== videoElement.duration) {
            this.setState({ ...this.state, mediaDuration: videoElement.duration != Infinity ? videoElement.duration : 200 })
        }
        if (this.props.player.playbackType != 'live') {
            this.setState({ ...this.state, currentDuration: videoElement.currentTime })
        }
    }

    errorEvent = (error) => {
        if (error && error.detail && error.detail.data && error.detail.data[0] && error.detail.data[0].data && error.detail.data[0].data[1] === 403) {
            this.props.playerError(error.detail.data[0].data);
        }
        else if (error && error.detail) {
            if (error.detail.data && error.detail.data[0] &&
                error.detail.data[0].data && error.detail.data[0].data[2]) {
                const serverResponse = error.detail.data[0].data[2]
                let parser = new DOMParser();
                let xmlDoc = parser.parseFromString(serverResponse, "text/xml");
                let CustomData = xmlDoc && xmlDoc.getElementsByTagName("CustomData")[0] && xmlDoc.getElementsByTagName("CustomData")[0].childNodes[0].nodeValue;
                let errorCode = JSON.parse(CustomData ? CustomData : null);
                if (errorCode.code === 130401) {
                    console.log("CAME TO ERROR EVENT playerError 2", error.detail)
                    this.props.playerError(error.detail);
                }
            }
        }

        else {
            error = {}
            this.props.playerError(error);
            window.playerRetry = true;
        }
        if (this.currentDuration > 0) {
            this.saveBookmark(this.currentDuration);
        }
        this.handlePlayRequest = false
        //if (this.state.isLoading) {
        this.setState({ ...this.state, isLoading: false })
        // }
    }

    gobackfromPlayer = () => {
        this.setState({ ...this.state, currentDuration: 0, mediaDuration: 0, playerLoaded: false, playbackStarted: false, isLoading: false, playing: false, showPlayer: false })
        this.resetCurrentDuration();
        this.props.closePlayer(this.props, false)
        this.props.history.goBack()
    }

    bufferEvent = () => {
        if (!this.state.isLoading)
            this.setState({ ...this.state, isLoading: true })
    }

    canplayEvent = () => {
        if (!this.state.playbackStarted || this.state.isLoading) {
            this.setState({ ...this.state, playbackStarted: true, isLoading: false })
        }
    }

    playingEvent = () => {
        const { playbackStarted, playbackType, trackingPlayId } = this.props.player;
        localStorage.PRODUCT_TYPE === "STREAMA" && talkToChannel({ payload: { playbackStarted, playbackType, trackingPlayId } });
        this.handlePlayRequest = false
        if (this.props.player.playbackType === 'live') {
            if (this.channelsMessagePayload() && !window.disclaimerMessage) {
                let self = this
                this.props.playerChannelsMessage(playerPopEnabledConfig());
                window.disclaimerMessage = true
            }
        }
        if (this.props.player.playbackType != 'live') {
            this.setState({ ...this.state, currentDuration: this.player.currentTime })
        }
        if (this.state.mediaDuration !== this.player.duration) {
            this.setState({ ...this.state, mediaDuration: this.player.duration != Infinity ? this.player.duration : 200 })
        }
    }

    pauseEvent = () => {
        const { playbackStarted, playbackType, trackingPlayId } = this.props.player;
        localStorage.PRODUCT_TYPE === "STREAMA" && talkToChannel({ payload: { playbackStarted, playbackType, trackingPlayId } });
    }

    playerTimeUpdateEvent = (e) => {
        if (localStorage.PRODUCT_TYPE === "XBOX") {
            if (!this.state.playbackStarted || this.state.isLoading) {
                this.setState({ ...this.state, playbackStarted: true, isLoading: false })
            }
        }
        var newSecond = Math.floor(e.target.currentTime);
        if (newSecond % 1 === 0 && this.currentDuration !== newSecond) {
            this.currentDuration = newSecond
            if (localStorage.PRODUCT_TYPE === "STREAMA") {
                if (newSecond > this.lastHeartbeat + 60) {
                    this.lastHeartbeat = newSecond
                    const { playbackStarted, playbackType, trackingPlayId } = this.props.player;
                    talkToChannel({ payload: { playbackStarted, playbackType, trackingPlayId } });
                }
            }
        }
    }

    initBitmovinLogs = () => {
        /*
        this.player.on("play", e => {
            this.setState({ ...this.state, playTimestamp: `playback initiated at : ${e.timestamp}` })
        });
        this.player.on(bitmovin.player.PlayerEvent.VideoPlaybackQualityChanged, e => {
            this.setState({ ...this.state, currentBitRate: `${e.sourceQuality.bitrate}`, targetBitRate: `${e.targetQuality.bitrate}` })
        });
        this.player.on(bitmovin.player.PlayerEvent.StallStarted, () => {
            if (!this.state.isLoading)
                this.setState({ ...this.state, isLoading: true, logs: ["Stream is now stalling"].concat(...this.state.logs) })
        });
        this.player.on(bitmovin.player.PlayerEvent.StallEnded, e => {
            if (this.state.isLoading) {
                this.setState({ ...this.state, isLoading: false, logs: ["Stream is no longer stalling"].concat(...this.state.logs) })
            }
        });
        this.player.on(bitmovin.player.PlayerEvent.DVRWindowExceeded, () => {
            this.setState({ ...this.state, isLoading: false, logs: [`Exceeded the DVR window... current time shift offset? ${this.player.getTimeShift()} seconds`].concat(...this.state.logs) })
        });
        this.player.on(bitmovin.player.PlayerEvent.Destroy, () => {
            this.setState({ ...this.state, logs: ['Player has been been destroyed'].concat(...this.state.logs) })
        });
        this.player.on("durationchanged", () => {
            this.setState({ ...this.state, logs: ['duration of the event has changed'].concat(...this.state.logs) });
        });
        this.player.on(bitmovin.player.PlayerEvent.Error, e => {
            this.setState({ ...this.state, logs: [`[ERROR] - errorCode: ${e.code || ""} errorName: ${e.name || ""}`].concat(...this.state.logs) })
        });
        this.player.on("periodswitched", () => {
            this.setState({ ...this.state, logs: ['Moved into a new period of chunks in the manifest...'].concat(...this.state.logs) })
        })*/
        this.player.on("segmentrequestfinished", () => {
            this.setState({
                ...this.state, logs: [
                    'completed downloading of segments!',
                    'cdn token is ' + (this.props.cdnToken && this.props.cdnToken.accessToken)].
                    concat(...this.state.logs)
            })
        });
        /*
        this.player.on("sourceunloaded", () => {
            this.setState({ ...this.state, logs: ['Source has been unloaded'].concat(...this.state.logs) })
        });
        this.player.on("targetlatencychanged", e => {
            this.setState({ ...this.state, logs: [`Target latency changed from ${e.from || ""} to ${e.to || ""}`].concat(...this.state.logs) })
        });
        this.player.on("timeshifted", () => {
            this.setState({ ...this.state, logs: [`Timeshifting complete, continue playback...`].concat(...this.state.logs) })
        });
        this.player.on("warning", e => {
            this.setState({ ...this.state, logs: [`[WARNING] - code: ${e.code} message: ${e.message}`].concat(...this.state.logs) })
        })
        this.player.on(bitmovin.player.PlayerEvent.AudioQualityChanged, () => {
            this.setState({ ...this.state, logs: ['The audio quality has changed...'].concat(...this.state.logs) })
        });*/
    }
    didCdnChannelTagChange(props) {
        const currentChannel = props.player.channel && props.player.channel.channelTag
        const cdnChannel = props.cdnToken && props.cdnToken.channelTag;
        return currentChannel === cdnChannel;
    }

    // Initialze Playback - wintergarten
    callWinterGarten = (nextProps, entitlementSettings) => {
        let settings = null;
        let self = this
        if (nextProps.player.playbackType === 'live') {
            if (!nextProps.player.channel) {
                nextProps.player.channel = nextProps.player.actionPayload && nextProps.player.actionPayload.payload
            }
        }
        const cdnAccessToken = nextProps.cdnToken && nextProps.cdnToken.accessToken;
        if (nextProps.player.channel && nextProps.player.playbackType === 'live') {
            const cdnAccessToken = nextProps.cdnToken && nextProps.cdnToken.accessToken;

            const isCdnAccessTokenError = nextProps.cdnToken.retryCount === nextProps.cdnToken.count && nextProps.cdnToken.error === CDN_SHORT_TOKEN_ERROR;
            if (wmeEnabled() && !this.didCdnChannelTagChange(nextProps)) {
                this.isWaitingForCdnToken = true;
            } else if (this.didCdnChannelTagChange(nextProps) && (cdnAccessToken || isCdnAccessTokenError)) {
                settings = this.getNativeLiveStreamSettings(
                    nextProps,
                    entitlementSettings
                );
            } else if (!wmeEnabled()) {
                settings = this.getNativeLiveStreamSettings(
                    nextProps,
                    entitlementSettings
                );
            } else {
                settings = this.getNativeLiveStreamSettings(nextProps, entitlementSettings)
            }
        } else if (nextProps.player.playbackType === "catchup") {
            settings = this.getNativeVodStreamSettings(nextProps, entitlementSettings, this.props.user)
            if (settings.bitmovin)
                settings.bitmovin.options.startTime = window.localStorage.getItem(StorageKey.SK_STORED_BOOKMARK) && window.localStorage.getItem(StorageKey.SK_STORED_BOOKMARK) > 0 ?
                    window.localStorage.getItem(StorageKey.SK_STORED_BOOKMARK) : settings.bitmovin.options.startTime;

            if (bookmarkIntervalEnabled()) {
                this.bookmarkTimer = setInterval(() => {
                    self.saveBookmark(self.currentDuration);
                }, parseInt(getBookmarkValue()) * 1000)
            }
        }
        if (settings) {
            //TODO: Remove Explora check after RRM deprecation
            if ((localStorage.getItem('PRODUCT_TYPE') === 'EXPLORA_ULTRA') || (irdetoCtrEnabled() && ((localStorage.getItem('PRODUCT_TYPE') === 'HISENSE' && playreadyVersion()) || localStorage.getItem('PRODUCT_TYPE') !== 'HISENSE'))) {

                if (this.channelsMessagePayload())
                    settings.channel4k = true
                settings.bitrateValue = this.getVideoQuality();
                this.player = window.Wintergarten(settings.videoType).initializeShakaPlayer(settings, function (type, request) {
                    if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
                        return licenseNetworkHelper(type, request)
                    }
                },
                    self.errorEvent);
                window.disclaimerMessage = false
                const videoPlayer = document.getElementById('player')
                this.player = ''
                this.player = videoPlayer
                this.player.removeEventListener("playing", this.playingEvent, false)
                this.player.removeEventListener("pause", this.pauseEvent, false)
                this.player.removeEventListener("timeupdate", this.playerTimeUpdateEvent, false)
                this.player.removeEventListener("canplay", this.canplayEvent, false)
                this.player.removeEventListener("ended", this.gobackfromPlayer, false)

                this.player.addEventListener("playing", this.playingEvent)
                this.player.addEventListener("pause", this.pauseEvent)
                this.player.addEventListener("timeupdate", this.playerTimeUpdateEvent)
                this.player.addEventListener("canplay", this.canplayEvent)
                this.player.addEventListener("ended", this.gobackfromPlayer)
            } else if (settings && !useNativePlayer()) {
                if (settings.bitmovinEnable && localStorage.PRODUCT_TYPE !== "HISENSE") {
                    this.player = window.Wintergarten(settings.videoType).initialize(settings);
                    window.disclaimerMessage = false
                    if (irdetoCtrEnabled()) {
                        this.player.on(bitmovin.player.PlayerEvent.DrmLicenseAdded, function (ev) {
                            let toRefresh = ev.license.id;
                            setTimeout(() => {
                                self.player.drm.renewLicense(toRefresh).then(function (data) {
                                    console.log("Update successful");
                                }).catch(console.error);
                            }, secureLocalStorage.getItem("x-irdeto-renewal-duration") ? secureLocalStorage.getItem("x-irdeto-renewal-duration") : 60000)
                        });
                    }
                    this.player.on(bitmovin.player.PlayerEvent.Error, (data) => {
                        this.handlePlayRequest = false
                        this.setState({ ...this.state, isLoading: false })
                        this.props.playerError(data);
                        window.playerRetry = true;

                    });
                    this.player.on(bitmovin.player.PlayerEvent.Playing, () => {
                        this.handlePlayRequest = false
                        let videoElement = this.player.getVideoElement()
                        videoElement.addEventListener(`timeupdate`, this.timeUpdateEvent)
                        this.setState({ showBackdrop: false, playbackStarted: true, isLoading: false });
                    });

                    this.player.on(bitmovin.player.PlayerEvent.Paused, () => {
                        this.setState({ ...this.state, isLoading: false })
                    });
                    this.player.on(bitmovin.player.PlayerEvent.PlaybackFinished, () => {
                        this.setState({ ...this.state, currentDuration: this.player.getCurrentTime(), mediaDuration: 0, playerLoaded: false, playbackStarted: false, isLoading: false, playing: false, showPlayer: false })
                        this.resetCurrentDuration();
                        this.props.closePlayer(this.props, false)
                        this.props.history.goBack()
                    });

                    this.player.on(bitmovin.player.PlayerEvent.Seeked, () => {
                        this.player.currentTime = nextProps.player.playFrom;
                        this.props.stopSeek()
                    });

                    if (window.adPlayer) {
                        window.adPlayer.addEventListener(OO.Pulse.AdPlayer.Events.AD_BREAK_STARTED, () => {
                            let adVideoElement = window.adPlayer.getContainer().querySelector('video');
                            if (adVideoElement) {
                                adVideoElement.setAttribute("poster", pulseAdsPreview)
                            }
                        });
                    }
                }
            } else {
                if (irdetoCtrEnabled()) {
                    if (window.timerError) {
                        clearTimeout(window.timerError);
                        window.timerError = null;
                    }
                    window.timerError = setTimeout(() => {
                        if (self.state.isLoading) {
                            window.playerRetry = true;
                            self.props.playerError({});
                        }
                    }, 15000);
                }
                if (settings) {
                    this.player = window
                        .Wintergarten(settings.videoType, productType)
                        .initialize(settings);
                    this.player.addEventCallback(this, this._onPlayerEvent);
                }
            }
            this.setState({ ...this.state, showPlayer: true, isLoading: true, fromError: false, playerLoaded: true, mediaDuration: 0, currentDuration: 0 })
            let activeMenu = nextProps.menu.data.filter(item => item.isActive === true)
            this.props.sendPlayEvent(activeMenu);
            if (window.adPlayer) {
                window.adPlayer.addEventListener(OO.Pulse.AdPlayer.Events.SHOW_SKIP_BUTTON, () => {
                    this.setState({ canSkip: true });
                });
            }
        }
    }


    getNativeLiveStreamSettings = (nextProps, entitlementSettings) => {
        let self = this
        return StreamHelper.GetNativeLiveStreamSettings(nextProps.player, entitlementSettings, nextProps.flagr, {
            onSuccess: function (player) {
                player.beginPlayback();
            },
            onError: function () {
                console.error('On error')
            }
        }, nextProps.cdnToken && nextProps.cdnToken.accessToken);
    }

    getNativeVodStreamSettings = (nextProps, entitlementSettings, user = null) => {
        let resumeTime = nextProps.player.actionPayload.episode.items[0].bookmarkTime
        if (!resumeTime) {
            resumeTime = 0
        }

        if (nextProps.player && nextProps.player.playBoxOfficeMovie) {

            return StreamHelper.GetNativeVodStreamSettings(nextProps.showpage.data[0], nextProps.player.video.movieEntitlement, user, nextProps.flagr, {
                onSuccess: function (player) {
                    player.beginPlaybackFrom(resumeTime);
                },
                onError: function () { console.Error('On error') }
            }, true, nextProps.player.playBoxOfficeMovie);

        } else if (nextProps.player && nextProps.player.playBoxOfficeTrailer) {
            return StreamHelper.GetNativeVodStreamSettings(nextProps.showpage.data[0], null, user, nextProps.flagr, {
                onSuccess: function (player) {
                    player.beginPlaybackFrom(resumeTime);
                },
                onError: function () { console.Error('On error') }
            }, true, nextProps.player.playBoxOfficeMovie, nextProps.player.playBoxOfficeTrailer);
        }

        let vodSettings = StreamHelper.GetNativeVodStreamSettings(nextProps.continueWatching && nextProps.continueWatching.isVisible && nextProps.continueWatching.item ? nextProps.continueWatching : nextProps.showpage && nextProps.showpage.data[1] ? nextProps.showpage.data[1] : nextProps.player.actionPayload.episode, entitlementSettings, user, nextProps.flagr, {
            onSuccess: function (player) {
                player.beginPlaybackFrom(vodSettings.startTime);
            },
            onError: function () {
                console.error('On error')
            }
        });
        return vodSettings
    }

    setVideoQuality = () => {
        try {
            window.player.selectVariantTrack(this.getQualityFromUserPrefs(), true);
        }
        catch (e) {
            window.player.selectVariantTrack(this.state.playerVariants[this.state.playerVariants.length - 1], true);
        }
    }

    getQualityFromUserPrefs = () => {
        let quality = secureLocalStorage.getItem("QUALITY_STORAGE_KEY") ? secureLocalStorage.getItem("QUALITY_STORAGE_KEY") : "1080p";
        secureLocalStorage.setItem("QUALITY_STORAGE_KEY", quality);
        try {
            return this.state.playerVariants.filter(e => e.height == quality.replace("p", ""))[0];
        } catch (e) {
            return this.state.playerVariants[this.state.playerVariants.length - 1];
        }
    }

    resetVodPlaybackStatesforAutoPlay = () => {
        this.setState({ ...this.state, currentDuration: 0, mediaDuration: 0, playerLoaded: false, playbackStarted: false, isLoading: false, playing: false, inActiveHours: 0, playbackCompleted: false })
    }

    stopPlayer = () => {
        clearInterval(this.bookmarkTimer);
        clearInterval(this.playerTimeout);
        this.saveBookmark(this.currentDuration);
        this.resetCurrentDuration();
        this.setState({ ...this.state, currentDuration: 0, mediaDuration: 0, playerLoaded: false, playbackStarted: false, isLoading: false, playing: false, showPlayer: false, inActiveHours: 0 })
        this.props.closePlayer(this.props, false)
        this.handlePlayRequest = false
        this.props.history.goBack()
        window.localStorage.removeItem(StorageKey.SK_STORED_BOOKMARK);
    }

    //Player Events
    _onPlayerEvent = (evt) => {
        var self = this
        if (internetIsConnected()) {
            if (self.state.mediaDuration !== this.player.getDuration()) {
                this.setState({ ...this.state, mediaDuration: this.player.getDuration() != Infinity ? this.player.getDuration() : 200 })
            }
            switch (evt.state) {
                case 'PAUSED':
                    self.setState({ ...self.state, isLoading: false })
                    break
                case 'PLAYING':
                    self.handlePlayRequest = false
                    if (!self.state.playbackStarted || self.state.isLoading) {
                        self.setState({ ...self.state, playbackStarted: true, isLoading: false })
                    }
                    if (self.props.player.playbackType != 'live') {
                        self.setState({ ...self.state, currentDuration: self.player.getCurrentTime() })
                    }
                    if (TrackingHelper.ShouldSendHeartbeat()) {
                        self.props.sendHeartbeatEvent(self.props.player, self.player.getCurrentTime());
                    }
                    break
                case 'BUFFERING':
                    if (!self.state.isLoading)
                        self.setState({ ...self.state, isLoading: true })
                    break
                case 'STOPPED':
                    self.setState({ ...self.state, playbackStarted: false, playing: false, isLoading: false })
                    break
                case 'COMPLETE':
                    this.stopPlayer()
                    break
                case 'ERROR':
                    console.error('ERROR')
                    this.handlePlayRequest = false
                    self.props.playerError(irdetoCtrEnabled() ? {} : evt);
                    break
                default:
                    break
            }
        } else {
            self.handleNetworkError()
        }
    }

    isWatermarkingSuportedDevice = () => {
        return (localStorage.getItem('PRODUCT_TYPE') === "XBOX" ||
            localStorage.getItem('PRODUCT_TYPE') === "WEBOS" ||
            localStorage.getItem('PRODUCT_TYPE') === "EXPLORA_ULTRA" ||
            localStorage.getItem('PRODUCT_TYPE') === 'STREAMA') ||
            localStorage.getItem('PRODUCT_TYPE') === 'TIZEN' ||
            (localStorage.getItem('PRODUCT_TYPE') === 'HISENSE' && playreadyVersion());
    }

    //JIRA ID: C2GP-159 - Player timeout event trigger in 30 seconds.
    timerToCheckPlayerStarted = () => {
        this.playerTimeout = setTimeout(() => {
            if (!this.state.playbackStarted) {
                this.props.videoPlaybackSegmentError(this.props.player);
                this.stopPlayer();
            }
            clearTimeout(this.playerTimeout);
        }, 30000);
    }


    componentDidUpdate(previousProps) {
        const prevChannelTag =
            previousProps.player.channel &&
            previousProps.player.channel.channelTag;

        const currentChannelTag =
            this.props.player.channel && this.props.player.channel.channelTag;

        if (
            this.props.player.playbackType === "live" &&
            currentChannelTag &&
            prevChannelTag !== currentChannelTag && wmeEnabled() &&
            this.isWatermarkingSuportedDevice()
        ) {
            this.props.getCdnToken({
                channelTag: this.props.player.channel.channelTag,
            });
        }

        if (this.props.player && this.props.player.isOpen && (!this.props.player.isLoading || this.props.player.shouldOnlySwitchStream) && StreamHelper.PlayBackCheck()) {
            try {
                if ((this.props.player.actionPayload || this.props.player.channel) && !this.props.player.error) {
                    this.showPlayer();
                    this.props.playVideo(this.props.player.actionPayload || this.props.player.channel);
                }
                this.timerToCheckPlayerStarted();
            } catch (e) {
                console.error(e, "video playback container.js lifecycle didupdate")
            }
        }
    }

    getPlayerClass = () => {
        let path = this.props.location.pathname.split('/')
        if (this.state.showPlayer || (path[2] === 'play' || path[4] === 'play'))
            return ''  //show black bg      
        return 'display-none'
    }

    getPlayer = (remoteFlagr) => {
        switch (true) {
            //TODO: Remove Explora check after RRM deprecation
            case ((localStorage.getItem('PRODUCT_TYPE') === 'EXPLORA_ULTRA') || (irdetoCtrEnabled() && ((localStorage.getItem('PRODUCT_TYPE') === 'HISENSE' && playreadyVersion()) || localStorage.getItem('PRODUCT_TYPE') !== 'HISENSE'))):
                return <video id="player" crossorigin="anonymous" className={this.getPlayerClass()}></video>
            case localStorage.PRODUCT_TYPE === 'WEBOS':
                return <React.Fragment>
                    <object id='broadcastVideoObject' type='video/broadcast'
                        style={{ position: 'absolute', width: '0px', height: '0px' }}></object>
                    <video id="player" class={this.getPlayerClass()}>
                        <source id="video_source" />
                    </video>
                </React.Fragment>
            case localStorage.PRODUCT_TYPE === 'TIZEN':
                return <object id="av-player" type="application/avplayer" class={this.getPlayerClass()}></object>
            case localStorage.PRODUCT_TYPE === 'HISENSE':
                return (<React.Fragment>
                    <object id='appmgr' type='application/oipfApplicationManager'
                        style={{ position: 'absolute', width: '0px', height: '0px', visibility: 'hidden' }}></object>
                    <object id="oipfDrmAgent" type="application/oipfDrmAgent"> </object>
                    <video id="player" class={this.getPlayerClass()}>
                        <source id="video_source" />
                    </video>
                </React.Fragment>)
            default:
                return <div id="player" class={this.getPlayerClass()}>
                </div>

        }
    }

    getAdPlayer = () => {
        return <div className={styles.adContainer} id="adContainer"></div>;
    }

    updateCurrentDuration = (duration) => {
        return this.setState({ ...this.state, currentDuration: duration })
    }

    resetCurrentDuration = () => {
        this.currentDuration = 0
        this.lastHeartbeat = 0
    }

    updatePlaybackState = (toggle) => {
        if (this.state.playbackStarted != toggle)
            return this.setState({ ...this.state, playbackStarted: toggle })
        return this.state;
    }

    preparePlayerInactiveDlg = () => {
        if (this.props.player && this.props.player.error && this.props.player.error.isVisible) {
            return null
        } else if (this.state.playbackStarted
            && this.props.player.playbackType === 'live'
            && this.props.player.idleScreenDialogue) {
            return <PlayerInactiveDlg {...this.props} {...this.state} clearPlayerInactiveTimer={this.clearPlayerInactiveTimer} idleScreenDialogue={this.props.player.idleScreenDialogue} destroyPlayer={this.destroyPlayer} stopPlayer={this.stopPlayer} />
        }
        return null
    }

    render() {
        let adPlayer = (localStorage.getItem('PRODUCT_TYPE') !== 'WEBOS' || localStorage.getItem('PRODUCT_TYPE') !== 'TIZEN' || localStorage.getItem('PRODUCT_TYPE') === 'XBOX' || (localStorage.getItem('PRODUCT_TYPE') === 'HISENSE' && playreadyVersion())) ? this.getAdPlayer() : null;
        let player = this.getPlayer(this.props.flagr)
        let playerUI = this.getPlayerUI()

        let error;
        let dlg = this.preparePlayerInactiveDlg();
        this.loader = null
        this.backdrop = null
        if ((this.state.isLoading && (this.props.player && !this.props.player.error)) || this.props.player.seeking)
            this.loader = <Loader fromSmartTv={true} isLoading={true} />
        if (!this.props.player.seeking && (this.props.player.playFrom !== -1) && (!this.state.playbackStarted || (this.state.isLoading && (this.props.player && !this.props.player.error)))) {
            this.backdrop = <div class={styles.backdrop} />
        }
        if (this.props.player && this.props.player.error && this.props.player.error.isVisible) {
            error = <TvError {...this.props.player.error} onSelected={[this.onErrorActionSelected, this.onErrorBackSelected]} isHisenseTv={localStorage.getItem('PRODUCT_TYPE') === 'HISENSE' && !playreadyVersion()} />
        }
        if (this.props.player && this.props.player.disclaimerMessage) {
            error = <TvError {...this.props.player.disclaimerMessage} isHisenseTv={localStorage.getItem('PRODUCT_TYPE') === 'HISENSE' && !playreadyVersion()} />
        }
        return (
            <div id='playerContainer' className={this.getPlayerClass()}>
                {this.loader}
                {this.backdrop}
                {adPlayer}
                {player}
                {playerUI}
                {error}
                {dlg}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        concurrency: state.concurrency,
        entitlement: state.entitlement,
        continueWatching: state.continueWatching,
        editorial: state.editorial,
        flagr: state.flagr,
        menu: state.menu,
        player: state.player,
        showpage: state.showpage,
        user: state.user,
        cdnToken: state.cdnToken,
        channels: state.channels,
        sections: state.sections
    }
};

const mapDispatchToProps = dispatch => ({
    playVideo: (payload) => dispatch(payload),
    DoEntitlement: (payload) => dispatch({ type: ENTITLEMENT, payload }),
    stopSeek: () => dispatch({ type: STOP_SEEK }),
    closePlayer: (props) => dispatch({ type: "CLOSE_PLAYER", payload: { channel: props.player.channel ? props.player.channel : {}, key: !props.player.manageDeviceError && props.player.channel && props.player.channel.key, 'episode': props.showpage && props.showpage.data ? props.showpage.data[1] : '', isCWRow: props.continueWatching && props.continueWatching.fromHome, sections: props.sections } }),
    saveBookmark: (payload) => dispatch({ type: SAVE_BOOKMARK, payload }),
    checkConcurreny: (payload) => dispatch({ type: GET_CONCURRENCY_STATUS, payload }),
    throwNetworkError: (payload) => dispatch({ type: PLAYER_ERROR, payload }),
    callAction: (type) => dispatch({ ...type }),
    sendHeartbeatEvent: (playbackItem, data) => dispatch({ type: PLAYER_CALLBACK_ISPLAYING, payload: { item: playbackItem, data: data } }),
    sendPlayEvent: (data) => dispatch({ type: PLAYER_CALLBACK_PLAY, payload: { data: data } }),
    androidPlayerError: (payload) => dispatch({ type: ANDROID_PLAYER_ERROR, payload }),
    playerError: (payload) => dispatch({ type: PLAYER_ERROR, payload }),
    playerPauseEvent: (payload) => dispatch({ type: PLAYER_CALLBACK_PAUSE, payload }),
    playerResumeEvent: (payload) => dispatch({ type: PLAYER_CALLBACK_RESUMED, payload }),
    showInactiveScreen: (payload) => dispatch({ type: 'SET_INACTIVE_SCREEN_FLAG', payload }),
    clearIdleScreenDlg: (payload) => dispatch({ type: 'CLEAR_IDLE_SCREEN', payload }),
    getCdnToken: (payload) => dispatch({ type: GET_CDN_SHORT_TOKEN, retryCount: DEFAULT_RETRY_COUNT, payload }),
    clearCdnToken: () => dispatch({ type: CLEAR_CDN_TOKEN }),
    playerChannelsMessage: (payload) => dispatch({ type: CHANNELS_REMOTECONFIG_MESSAGE, payload }),
    videoPlaybackSegmentError: (payload) => dispatch({ type: "VIDEO_PLAYBACK_ERROR", payload })

})

export default connect(mapStateToProps, mapDispatchToProps)(PlayerContainer);