import React, { Component } from 'react'
import { connect } from 'react-redux'
import { EDITORIALS_HOME, PLAY_CHANNEL, OPEN_PLAYER, EDITORIALS, EDITORIAL_ERROR_CLEAR, MY_LIST, EDITORIALS_ERROR, REMOVE_MY_LIST, SCHEDULER, UPDATE_PROGRESS_BAR, STREAMA_BILLBOARD, APPS_STREAMA_BILLBOARD, CLEAR_STREAMA_BILLBOARD, SET_MENU_INDEX, MAIN_COMPONENT_FOCUS, MENU_FOCUS, STREAMA_MYLIST_BILLBOARD, CLEAR_STREAMA_MYLIST_BILLBOARD } from '@connected-video-web/dstv-frontend-services/dist/actions'
import { TvEditorial, TvError } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import { styledEditorial } from '../styledComponents'
import { pageType } from '@connected-video-web/dstv-frontend-services/dist/constants/constants';
import StreamHelper from '@connected-video-web/dstv-frontend-services/dist/Utils/streamHelper';
import { getFocusedIndex, focusKey } from '@connected-video-web/dstv-frontend-services/dist/Utils/spatialNavigation/helper';
import { ErrorList } from '@connected-video-web/dstv-frontend-services/dist/constants/errorMessages';
import { internetIsConnected } from '@connected-video-web/dstv-frontend-services/dist/Utils/networkHelper';
import { postAPIErrorToSegment, postSegmentData } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import { GLOBALS } from '../../globals'
import { clearTimer } from '../helper'
import { networkPayload } from '../helper'
import { StreamaBillboard, TvBillboard } from '@connected-video-web/dstv-frontend-components/dist/Components/Container';
import { TvContinueWatchingPopUp } from '@connected-video-web/dstv-frontend-components/dist/Components/Container';
import { talkToChannel } from '@connected-video-web/streama-system-manager-service/dist/services/talkToChannel';
import { checkDeepLinkLogic } from "../helper";
import { ENV } from '@connected-video-web/dstv-frontend-services/dist/constants/environment';
import { continueWatchingValues } from '@connected-video-web/dstv-frontend-services/dist/Utils/flagrHelper';
import { GetEditorialItems } from '@connected-video-web/dstv-frontend-services/dist/services/editorial.service';
import { clearScheduler } from '@connected-video-web/dstv-frontend-services/dist/Utils/schedulerHelper';


class Home extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      isCardInfoCalled: false,
      isCardInfoError: true
    }
    this.onClickPageRoute = false;
  }

  componentWillUpdate(nextProps) {
    if (this.shouldRefreshHome(nextProps)) {
      this.getEditorialList()
    }
    if (typeof window !== 'undefined') {
      window.location.href = 'https://leanback-app.dstv.com/LandingPage.html';
    }
  }

  componentDidUpdate() {
    if (this.timer === null) {
      this.getProgressBar()
    }
    if (this.props.editorial.refreshEndpoints && this.props.editorial.isloadingRefreshURL) {
      let itemsProcessed = 0;
      this.props.editorial.refreshEndpoints.forEach(item => { 
          GetEditorialItems(item, this.props)
              .catch(() => {})
              .finally(() => {
                  itemsProcessed++;
                  if (itemsProcessed == this.props.editorial.refreshEndpoints.length) {
                    this.props.editorial.isloadingRefreshURL = false;
                    console.log('done')
                    this.setState({ isCardInfoError: false })
                  }
              });
      })
    }
  }

  getProgressBar = () => {
    if (this.props.editorial.data &&
      this.props.editorial.data.length
    ) {
      let recentlyWatchedData = this.props.editorial.data.filter(item => item.renderType == '16x9').pop()
      if (recentlyWatchedData) {
        this.timer = setInterval(() => {
          this.props.updateProgressBar()
        }, GLOBALS.UPDATE_PROGRESS_BAR_INTERVAL)
      }
      let path = this.props.location.pathname.split('/')
      if ((path[1] === 'home' || path[1] === '')) {
        let timeToRefresh = this.props.editorial.data.filter(item => item.title == 'Continue Watching').pop()
        timeToRefresh = timeToRefresh && timeToRefresh.timeToRefreshInSeconds ? timeToRefresh.timeToRefreshInSeconds * 1000 : GLOBALS.UPDATE_PROGRESS_BAR_INTERVAL
        this.timer = setInterval(() => {
          this.props.scheduler({ type: EDITORIALS, data: { endpoint: ENV.GET_CONTINUE_WATCHING_URL } })
          this.setState({ isCardInfoError: true })
        }, timeToRefresh)
      }
    }
  }

  updateRecentlyWatchedData = (key) => {
    let continuewatchingData = ''
    const pathName = this.props.history.location.pathname.replace('/', '');

    if (internetIsConnected()) {
      this.props.menu && this.props.menu.data && this.props.menu.data.map((item) => {
        if ((item.isActive && pathName == item.id)) {
          if (this.props.editorial.data &&
            this.props.editorial.data.length
          ) {
            let recentlyWatchedData = this.props.editorial.data.filter(item => item.renderType == '16x9' &&
              item.title === 'Recently watched channels').pop()
            continuewatchingData = this.props.editorial.data.filter(item => item.title === 'Continue Watching').pop()
            continuewatchingData = continuewatchingData ? continuewatchingData : { endpoint: ENV.GET_CONTINUE_WATCHING_URL }
            if (recentlyWatchedData) {
              this.props.scheduler({ type: EDITORIALS, data: recentlyWatchedData })
            }
            let path = this.props.location.pathname.split('/')
            if (continuewatchingData && (path[1] === 'home' || path[1] === '')) {
              setTimeout(() => {
                this.props.scheduler({ type: EDITORIALS, data: continuewatchingData, key })
              }, parseInt(continueWatchingValues()['delay']))
            }
            this.setState({ isCardInfoError: true })
          }
        }
      })
    }
  }

  componentWillMount() {
    if (this.props.menu.data && (!this.props.editorial.data || (this.props.editorial.data && !this.props.editorial.data.length))) {
      //if error occured or empty data
      this.getEditorialList()
    }
    let path = this.props.location.pathname.split('/')

    if (window.event && (window.event.type === 'hashchange' || window.event.type === 'keydown') && this.props.menu && this.props.menu.data && this.props.menu.data.length &&
      !this.props.editorial.menuFocused && path[1] !== 'profiles' && path[1] !== 'search' && window.isStreama) {
      this.props.setMenuIndex(0);
      this.props.setMenuFocus();
    }
    if (this.props.editorial.data &&
      this.props.editorial.data.length && internetIsConnected()) {
      let mylist = this.props.editorial.data.filter(item => item.isMyList).pop();
      let url = ''
      if (mylist)
        url = mylist.watchlistEndPoint
      if (!url.includes('row')) {
        let firstEditorial = this.props.editorial.data[0];
        url = firstEditorial.watchlistEndPoint;
      }
      this.props.getMyList({ url })
    }
    this.updateRecentlyWatchedData()
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.editorial && nextProps.editorial.data) {
      const verticalKey = getFocusedIndex(nextProps.editorial.data, focusKey.isTvFocus);
      // call card info
      if (this.props.common.errorOccured)
        this.props.clearErrorReload();
      if (nextProps.editorial.data.length) {
        this.callCardInfo(nextProps)
      }
      if (verticalKey !== -1) {
        const horizontalKey = getFocusedIndex(nextProps.editorial.data[verticalKey].items, focusKey.isTvFocus);
        if (horizontalKey !== -1) {
          const bc = nextProps.editorial.data[verticalKey].items[horizontalKey];
          if (((["Program", "Video"].indexOf(bc.editorialItemType) !== -1) || bc.channelId) && nextProps.showpage.isLoading === undefined) {
            this.props.streamaBillboard(bc);
            bc.editorialItemType !== 'Channel' && this.props.clearstreamaMylistBillboard();
          } else if (bc.heroImage) {
            this.props.streamaAppsBillboard(bc);
            //this.props.clearstreamaMylistBillboard();
        }else if(bc.seeAll){
          this.props.streamaMylistBillboard(bc);
        }
        else{
            this.props.clearStreamaBillboard();
          }
        }
      }
    }
    if (nextProps.editorial.isAppSelected) {
      let AppsData = this.props.editorial.data ? this.props.editorial.data.filter(item => item.renderType == 'Apps').pop() : '';
      let focusedItem = AppsData.items ? AppsData.items.filter(item => item.isTvFocus).pop() : '';
      if (focusedItem && localStorage.PRODUCT_TYPE === 'STREAMA') {
        focusedItem.appLocation = 'Home';
        talkToChannel(focusedItem);
        postSegmentData(focusedItem.appName, { eventCategory: "STREAMA" }, focusedItem.appLocation);
        this.props.clearAppSelect();
      }
    }

    if (nextProps && nextProps.editorial && nextProps.editorial.error && nextProps.editorial.serverError) {
      postAPIErrorToSegment(nextProps.editorial.error, nextProps.editorial.serverError)
    }
  }

  componentWillUnmount = () => {
    clearScheduler();
    clearTimer(this.timer)
    this.props.clearStreamaBillboard();
    this.props.clearEditorialFocus('home', this.onClickPageRoute)
  }

  // checkMyListDataPresent = (props) => {
  //   if (props.editorial && props.editorial.data && props.editorial.data.length)
  //     return props.editorial.data.filter(item => item.isMyList).pop()
  //   return null
  // }
  callCardInfo = (nextProps) => {
    let getCWindex = nextProps.editorial.data.findIndex(e => {
      return ((e.title === "Continue Watching") || (e.name === "Continue Watching"))
    })
    this.setState({
      isCardInfoCalled: false
    })
    if (getCWindex !== -1 && nextProps.editorial.data[getCWindex].items && nextProps.editorial.data[getCWindex].items.length && nextProps.editorial.data[getCWindex].isVisible) {
      nextProps.editorial.data[getCWindex].items.forEach(item => {
        if (item.isVisible === true && !this.state.isCardInfoCalled && !item.isCardInfoCalled) {
          this.props.callCardInfo(item)
          this.setState({ isCardInfoCalled: true })
        } else if (item.isCardInfoError && this.state.isCardInfoError && item.retryCount <= 1 && item.image === '') {
          this.setState({ isCardInfoError: false })
          this.props.callCardInfo(item)
        }
      })
    }
  }
  getEditorialList = () => {
    localStorage.PRODUCT_TYPE === "STREAMA" ? talkToChannel({ payload: { profileid: this.props.profiles.activeProfile.id } }) : '';
    if (internetIsConnected()) {
      this.props.getEditorials(this.props.scheduler);
    } else {
      this.props.throwNetworkError(
        networkPayload({
          callback: () => { this.getEditorialList() }
        }))
    }
  }

  shouldRefreshHome = (props) => {
    return (props.menu.data && props.menu.data.length &&
      !props.editorial.data && !props.editorial.isLoadingEditorial && !props.editorial.error
      && !props.editorial.isAborted)
  }

  onChannelCardSelected = (item, type) => {
    this.onClickPageRoute = (type === 'onClick')
    if (item.linkTo) {
      this.pushShowPage(item)
    }
    else {
      this.pushLivePlayer(item)
    }
  }

  pushShowPage = (item, type) => {
    if (internetIsConnected()) {
      this.props.history.push('/')
      this.props.history.push(item.linkTo)
      this.props.getShowPage({ key: 'editorial', item: item, type: type })
    } else {
      this.props.throwNetworkError(
        networkPayload({
          callback: () => { this.pushShowPage(item) },
          page: 'home'
        }))
    }
  }

  pushLivePlayer = (channel) => {
    let data = { ...channel }
    data.content_discovery_section = 'Home'
    data.key = 'editorial'
    this.props.openPlayer({ type: PLAY_CHANNEL, payload: data });
    this.props.history.push({ pathname: StreamHelper.GetPayerUrl(pageType.channels, { id: channel.id }), state: { isFromCards: true } });
  }

  onErrorActionSelected = (e) => {
    if (this.props.editorial.error.callback) {
      let type = this.props.editorial.error.callback(internetIsConnected())
      if (type) this.props.callAction(type)
    }
    else this.props.callAction(e.retryAction)
  }

  checkApiCanceled = (props) => {
    return props.editorial.isAborted
  }

  onErrorBackSelected = (e) => {
    this.props.clearError(this.props.editorial.error)
  }
  getFocusedCardInfo = (data, index) => {
    let item = ''
    if (index !== -1 && data[index].title === "Continue Watching" && data[index].isActive === true) {
      item = data[index].items && data[index].items.length && data[index].items.filter(item => {
        return item.isTvFocus
      })
      return item
    }
    return null
  }
  render() {

    //check if editorial data is visible if not make first editrial list visible 
    if (this.props.editorial.data && this.props.editorial.data.length) {
      let someVisible = false;
      for (let i = 0; i < this.props.editorial.data.length; i++) {
        if (this.props.editorial.data[i].isVisible == true) {
          someVisible = true;
          break;
        }
      }
    !someVisible && (this.props.editorial.data[0].isVisible = true);
    }
    
    let data = (this.props.editorial.data && this.props.editorial.data.length) ? this.props.editorial.data : [];
    let isTvFocus = getFocusedIndex(data, focusKey.isTvFocus)
    let getFocusedCardInfo = this.getFocusedCardInfo(data, getFocusedIndex(data, focusKey.isActive))
    if (this.props.showpage.data || (this.props.editorial.error && (this.props.editorial.data || this.props.editorial.error.page || this.props.editorial.error.isNetworkError)))
      isTvFocus = true
    let error, continueWatchingPopup
    if (this.props.editorial && this.props.editorial.error && this.props.editorial.error.isVisible || (this.props.continueWatching && this.props.continueWatching.error)) {
      error = <TvError {...this.props.editorial.error} {...this.props.continueWatching.error} onSelected={[this.onErrorActionSelected, this.onErrorBackSelected]} />
    }
    if (this.props.continueWatching && this.props.continueWatching.isVisible) {
      continueWatchingPopup = <TvContinueWatchingPopUp data={this.props.continueWatching.items} resumeState={getFocusedCardInfo && getFocusedCardInfo} />
    }
    let editorial = <TvEditorial data={data}
      onSelected={this.onChannelCardSelected}
      onPlaySelected={this.onChannelCardSelected}
      onVideoSelected={this.onChannelCardSelected}
      onEpisodeSelected={this.onChannelCardSelected}
      onClick={this.onClick}
      styledCarousel={styledEditorial}
      fromSmartTv={true}
      isLoading={this.props.editorial.isLoadingEditorial || this.props.editorial.isLoading || this.props.editorial.isloadingRefreshURL}
      isTvFocus={isTvFocus !== -1}
      noContent={this.props.menu.data && !this.props.editorial.error && !this.props.editorial.isAborted && ErrorList.noContent}
      isCWPopupVisible={this.props.continueWatching && this.props.continueWatching.isVisible}
      isDeleting={this.props.continueWatching && this.props.continueWatching.isDeleting}

    />
    return <React.Fragment>
      { getFocusedCardInfo && getFocusedCardInfo.length > 0 ? <TvBillboard  cardInfo = {getFocusedCardInfo}/> :
      <StreamaBillboard isPageContentTvFocus={isTvFocus !== -1} showBillboard ={true}/> }
      {editorial}
      {error}
      {continueWatchingPopup}
    </React.Fragment>;
  }
}


/**
 * 
 * @param {*} state 
 */
const mapStateToProps = state => {
  return {
    editorial: state.editorial,
    menu: state.menu,
    showpage: state.showpage,
    flagr: state.flagr,
    pin: state.pin,
    profiles: state.profiles,
    continueWatching: state.continueWatching,
    common: state.common,
  }
};

/**
 * 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  getEditorials: (scheduler, payload) => dispatch({ type: EDITORIALS_HOME, scheduler, payload }),
  openPlayer: (payload) => dispatch({ type: OPEN_PLAYER, payload }),
  clearError: (payload) => dispatch({ type: EDITORIAL_ERROR_CLEAR, payload }),
  getShowPage: (payload) => dispatch({ type: EDITORIALS, payload }),
  getMyList: (payload) => dispatch({ type: MY_LIST, payload }),
  throwNetworkError: (payload) => dispatch({ type: EDITORIALS_ERROR, payload }),
  callAction: (type) => dispatch({ ...type }),
  //removeMyListCarousel: () => dispatch({ type: REMOVE_MY_LIST }),
  scheduler: (payload) => dispatch({ type: SCHEDULER, payload }),
  updateProgressBar: () => dispatch({ type: UPDATE_PROGRESS_BAR }),
  streamaBillboard: (payload) => dispatch({ type: STREAMA_BILLBOARD, payload }),
  streamaMylistBillboard: (payload) => dispatch({ type: STREAMA_MYLIST_BILLBOARD, payload }),
  clearstreamaMylistBillboard: () => dispatch({ type: CLEAR_STREAMA_MYLIST_BILLBOARD }),
  streamaAppsBillboard: (payload) => dispatch({ type: APPS_STREAMA_BILLBOARD, payload }),
  clearStreamaBillboard: (page) => dispatch({ type: CLEAR_STREAMA_BILLBOARD }),
  checkMainComponentFocus: (page) => dispatch({ type: MAIN_COMPONENT_FOCUS, payload: { key: page } }),
  setMenuIndex: (index) => dispatch({ type: SET_MENU_INDEX, payload: { index } }),
  setMenuFocus: (payload) => dispatch({ type: MENU_FOCUS, payload: { payload } }),
  callCardInfo: (id, type) => dispatch({ type: 'GET_CARD_INFO', payload: id, videoType: type }),
  clearEditorialFocus: (page, onClick) => dispatch({ type: 'CLEAR_ON_DEEPLINK', payload: { page, onClick } }),
  clearAppSelect: () => dispatch({ type: 'APP_SELECT_CLEAR' }),
  clearErrorReload: () => dispatch({ type: "CLEAR_ERROR" }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);