import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { MENU, EDITORIALS_HOME, HOME, GET_USER_INFO, EMAIL_SIGN_UP_IN_PROGRESS } from '@connected-video-web/dstv-frontend-services/dist/actions'
import Menu from './components/menu'
import SubMenu from './components/submenu'
import PlayerContainer from './components/player/Container'
import { KeyCode } from './keyCodeMapping';
import { Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import { Login, Showpage, Pin, Onboarding, DeprecatedDevice } from './components/page'
import { Profile } from './components/page/profile';
import { PageWrapper } from "@connected-video-web/dstv-frontend-components/dist/Components/Container";
import { getSessionID, retriveFromLocalStorage } from '@connected-video-web/dstv-frontend-services/dist/Utils/storageHelpers';
import { androidLoginString } from '@connected-video-web/dstv-frontend-services/dist/Utils/authHelper'
import { SENTRY_DSN_KEYS } from '@connected-video-web/dstv-frontend-services/dist/constants/keys';
import { deviceDeprecationList, SIGNUP_SUCCESSFUL } from '@connected-video-web/dstv-frontend-services/dist/constants/constants'
import { GLOBALS } from './globals'
import SessionHelper from '@connected-video-web/dstv-frontend-services/dist/Utils/sessionHelper'
import { registerEvents } from './events';
import { registerNetworkListenerForTv } from '@connected-video-web/dstv-frontend-services/dist/Utils/networkHelper';
import { segmentId, APP_VERSION, DATADOG_DETAILS } from '@connected-video-web/dstv-frontend-services/dist/constants/environment';
import { openChannel } from "@connected-video-web/streama-system-manager-service/dist/services/openChannel";
import { secureLocalStorage, secureSessionStorage } from "@connected-video-web/dstv-frontend-middleware/dist/Utils/secureStorage";
import styles from './base-styles/main.scss';
let productType = localStorage.getItem('PRODUCT_TYPE');
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import { TvSplashPage } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages/TvSplashPage';
import { getDeviceName } from '@connected-video-web/dstv-frontend-services/dist/Utils/platform';
import { TvError } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import ErrorBoundary from './ErrorBoundary';
import { datadogRum } from '@datadog/browser-rum'

var ANDROID_JAVASCRIPT_INTERFACE = "android_javascript_interface"
class App extends Component {

  constructor(props) {
    super(props);
    this.state = { subscriptionStatus: null };
    localStorage.PRODUCT_TYPE === 'STREAMA' ? setTimeout(() => {
      openChannel();
    }, 600) : '';
    registerEvents(props);
    registerNetworkListenerForTv()
    window.analytics.load(segmentId);
    this.userInfoCalled = false
    this.payload = {
      userAgent: navigator.userAgent,
      deviceType: localStorage.getItem('PRODUCT_TYPE')
    }
  }

  getDsnKeyByPlatform = (productType, dsn_keys = {}) => {
    if (Object.keys(dsn_keys).length > 0) {
      return dsn_keys[productType];
    } else {
      return ""
    }
  };

  componentDidMount() {
    let sessionId = getSessionID()

    this.checkGodzilla();
    if (window.isAndroidTV) {
      this.storeDeviceDetails()

      if (sessionId) {
        if (window[ANDROID_JAVASCRIPT_INTERFACE]) {
          window[ANDROID_JAVASCRIPT_INTERFACE].onLoginSuccess(androidLoginString());
        } else {
          console.error("Android TV native app interface not present.");
        }
      }
    }

    if (DATADOG_DETAILS) {
      let appVersion = APP_VERSION;
      if (/staging|localhost|sit/.test(window.location.hostname)) {
        console.log('logging to debug on datadog')
        appVersion = appVersion + '-debug';
      }
      datadogRum.init({
        applicationId: DATADOG_DETAILS[0],
        clientToken: 'pub7d53fb1f1c51cdbf5d29408734960f41',
        site: 'datadoghq.eu',
        service: DATADOG_DETAILS[1],

        // Specify a version number to identify the deployed version of your application in Datadog
        version: appVersion,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 10,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input'
      });

      datadogRum.setUser({
        id: secureLocalStorage.getItem('userID')
      })
      datadogRum.startSessionReplayRecording();
    }
  }

  componentWillUpdate(nextProps) {
    if (nextProps.flagr !== this.props.flagr) {
      if (nextProps.flagr.enableSentry) {
        if (!this.props.enableSentry) {
          console.log("Sentry Enabled")
          const sentryConfig = nextProps.flagr.configs.find(configKey => configKey.flagKey === "enable_sentry");
          Sentry.init({
            dsn: this.getDsnKeyByPlatform(productType, SENTRY_DSN_KEYS),
            integrations: [
              new Integrations.BrowserTracing()
            ],
            ignoreErrors: sentryConfig.payload && sentryConfig.payload.ignore_errors ? JSON.parse(sentryConfig.payload.ignore_errors) : [],
            denyUrls: sentryConfig.payload && sentryConfig.payload.deny_urls ? JSON.parse(sentryConfig.payload.deny_urls) : [],
            allowUrls: sentryConfig.payload && sentryConfig.payload.allow_urls ? JSON.parse(sentryConfig.payload.allow_urls) : [],
            tracesSampleRate: sentryConfig.payload && sentryConfig.payload.sample_rate ? parseFloat(sentryConfig.payload.sample_rate) : 0,
          });
          Sentry.setUser({ id: secureLocalStorage.getItem('userID') });
          Sentry.setTag("dstv_app_version", APP_VERSION);
          if (window.isAndroidTV) {
            const urlParams = new URLSearchParams(window.location.search)
            Sentry.setTag("dstv_apk_version", urlParams.get("app_ver") ? urlParams.get("app_ver") : "");
          }
        }
      }
    }
  }

  navigation = (e) => {
    e.preventDefault()
    if (KeyCode(e.keyCode))
      this.props.TvFocus(KeyCode(e.keyCode), this.props.history);
  }

  chooseFirstLandingPage = () => {
    if (this.deviceDeprecated(this.payload)) {
      return <Route path="/" component={DeprecatedDevice} />
    }

    let sessionId = getSessionID()

    const isSignUpFlow = !!(
      (this.props.login &&
        this.props.login.streamaLoginData &&
        this.props.login.streamaLoginData.action === SIGNUP_SUCCESSFUL) ||
      (!!this.props.login.streamaStepStatus &&
        this.props.login.streamaStepStatus === EMAIL_SIGN_UP_IN_PROGRESS))

    let isMenuFocused = this.props.menu && this.props.menu.data && this.props.menu.data.filter(item => item.isTvFocus).pop()
    if (!isMenuFocused) {
      if (this.props.profiles && this.props.profiles.activeProfile && this.props.profiles.activeProfile.isTvFocus)
        isMenuFocused = this.props.profiles.activeProfile
      else if (this.props.profiles && this.props.profiles.isSearchIconFocused)
        isMenuFocused = this.props.profiles.isSearchIconFocused
    }


    if (!sessionId || (isSignUpFlow && !this.state.subscriptionStatus)) {
      this.storeDeviceID()
      if (localStorage.PRODUCT_TYPE === 'STREAMA') {
        return (
          <Route
            path="/"
            render={(props) => (
              <Onboarding
                {...props}
                setSubscriptionStatus={(subscriptionStatus) => this.setState({ subscriptionStatus })}
              />
            )}
          />
        );
      } else {
        return <Route path="/" component={Login} />
      }
    } else if (this.props.user && !this.props.user.isLoading && !this.props.userPackage && !this.props.user.country || Object.keys(this.props.user).length === 0) {
      if (!this.userInfoCalled) {
        this.userInfoCalled = true
        return this.props.getUserInfo()
      }
    } else if (this.props.user.isLoading && this.userInfoCalled) {
      return <TvSplashPage />
    }
    else if (retriveFromLocalStorage('PIN_STORAGE_KEY') !== null && this.props.pin.onFirstLanding) {
      return <React.Fragment>
        <Pin fromAction={'WELCOME_BACK'} />
        {this.props.login.isForgotPin && <Route path="/" component={Login} />}
      </React.Fragment>
    }
    else if (this.props.profiles.activeProfile && this.props.history.location.pathname !== '/profiles') {
      // let isMenuFocussed = this.props.menu && this.props.menu.data && this.props.menu.data.filter(item => item.isTvFocus).pop()
      return (<React.Fragment>
        <PageWrapper shouldHide={!isMenuFocused}>
          <Menu hideOverlay={!isMenuFocused} />
          <Route path="/" component={Showpage} />
          {this.props.login.isForgotPin && <Route path="/" component={Login} />}
          <Route path="/settings/pin" component={Pin} />
          <Route path={["/livetv", "/tvshows", "/movies", "/sport", "/kids", "/channelgroups"]} component={SubMenu} />
          <Route path="/" component={LandingPage} />
          {window.location.href.indexOf("staging") !== -1 && <span className={styles.domain}>v {APP_VERSION} - 1 - {window.location.href}</span>}
        </PageWrapper>
        <Route path="/" component={PlayerContainer} />
      </React.Fragment >)
    }
    else if (this.props.profiles.activeProfile && !this.props.common.errorOccured) {
      // let isMenuFocussed = this.props.menu && this.props.menu.data && this.props.menu.data.filter(item => item.isTvFocus).pop()
      return (<React.Fragment>
        <PageWrapper shouldHide={!isMenuFocused}>
          <Menu hideOverlay={!isMenuFocused} />
          <Route path="/" component={Showpage} />
          {this.props.login.isForgotPin && <Route path="/" component={Login} />}
          <Route path="/settings/pin" component={Pin} />
          <Route path={["/livetv", "/tvshows", "/movies", "/sport", "/kids", "/channelgroups"]} component={SubMenu} />
          <Route path="/" component={LandingPage} />
          {window.location.href.indexOf("staging") !== -1 && <span className={styles.domain}>{window.location.href}</span>}
        </PageWrapper>
        <Route path="/" component={PlayerContainer} />
      </React.Fragment>)
    }
    else {
      var params = this.props.location.pathname.split('/')
      return <Profile {...this.props} page={params} />
    }
  }

  deviceDeprecated = (payload) => {
    var unsupportedDeviceChipsets = [];
    var deviceNotSupported = false;
    deviceDeprecationList.map(function (device) {
      if (device.name === payload.deviceType) {
        device.unsupportedDeviceChipsets.forEach((item) => {
          unsupportedDeviceChipsets.push(item)
        })
      }
    });

    if (unsupportedDeviceChipsets.length) {
      for (var i = 0; i < unsupportedDeviceChipsets.length; i++) {
        if (payload.userAgent.includes(unsupportedDeviceChipsets[i])) {
          deviceNotSupported = true;
          break;
        }
      }

      if (!deviceNotSupported) {
        if (payload.userAgent.includes("A6501EX") ||
          payload.userAgent.includes("A6101EA") &&
          payload.userAgent.includes("Odin") &&
          payload.userAgent.includes("MSD6886"))
          deviceNotSupported = true;
      }
    }
    return deviceNotSupported;
  }
  checkGodzilla = () => {
    try {
      fetch('https://now.dstv.com/godzilla.json', { cache: "no-store" })
        .then((response) => response.json())
        .then((json) => {
          if (json.enabled) {
            window.location.href = 'https://now.dstv.com/fallback/godzilla.html?device=' + window.location.origin + window.location.pathname + '&product_type=' + localStorage.PRODUCT_TYPE;
          }
        });

    } catch (e) { }
  }
  storeDeviceDetails = () => {
    const urlParams = new URLSearchParams(window.location.search)
    secureLocalStorage.setItem("app_ver", urlParams.get("app_ver") ? urlParams.get("app_ver") : "");
    secureLocalStorage.setItem("device_name", urlParams.get("device_name") ? urlParams.get("device_name") : getDeviceName());
    secureLocalStorage.setItem("device_type", urlParams.get("device_type") ? urlParams.get("device_type") : "");
    secureLocalStorage.setItem("drm", urlParams.get("drm") ? urlParams.get("drm") : "");
    secureLocalStorage.setItem("hdcp", urlParams.get("hdcp") ? urlParams.get("hdcp") : "");
    secureLocalStorage.setItem("os_v", urlParams.get("os_v") ? urlParams.get("os_v") : "");
    secureLocalStorage.setItem("os", urlParams.get("os") ? urlParams.get("os") : "");
    secureLocalStorage.setItem("platform_id", urlParams.get("platform_id") ? urlParams.get("platform_id") : "");
    secureLocalStorage.setItem("security_level", urlParams.get("security_level") ? urlParams.get("security_level") : "");
    secureLocalStorage.setItem("session_type", urlParams.get("session_type") ? urlParams.get("session_type") : "");
  }

  storeDeviceID = () => {
    //Look for device ID being passed from the Android TV wrapper app
    if (window.isAndroidTV) {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams && (urlParams.has('device_id'))) {
        let androidUUID = urlParams.get('device_id');
        localStorage.setItem(GLOBALS.DEVICE_ID_STORAGE_KEY, androidUUID);
      }
    }
    if (localStorage.getItem(GLOBALS.DEVICE_ID_STORAGE_KEY)) {
      GLOBALS.DEVICE_ID = localStorage.getItem(GLOBALS.DEVICE_ID_STORAGE_KEY)
    } else {
      GLOBALS.DEVICE_ID = SessionHelper.getRandomDeviceId()
      localStorage.setItem(GLOBALS.DEVICE_ID_STORAGE_KEY, GLOBALS.DEVICE_ID)
    }
  }
  render() {
    if (this.props.user && this.props.user.error && this.props.user.error.isVisible) {
      return <TvError {...this.props.user.error} />
    }
    return (<ErrorBoundary>
      <div id="app" className={!this.props.flagr.enableAnimations && styles.disable__transitions}>
        {this.chooseFirstLandingPage()}
      </div>
    </ErrorBoundary>
    );
  }
}


/**
 * 
 * @param {*} state 
 */
const mapStateToProps = state => {
  return {
    connect: state.connect,
    menu: state.menu,
    profiles: state.profiles,
    login: state.login,
    flagr: state.flagr,
    pin: state.pin,
    user: state.user,
    subscription: state.subscription,
    common: state.common
  }
};

/**
 * 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  getUserInfo: () => dispatch({ type: GET_USER_INFO }),
  getMenu: (payload) => dispatch({ type: MENU, payload }),
  getEditorials: (scheduler, payload) => dispatch({ type: EDITORIALS_HOME, scheduler, payload }),
  TvFocus: (type, payload) => dispatch({ type: type, payload }),
  HomePressed: () => dispatch({ type: HOME }),
  callAction: (type) => dispatch({ ...type }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
