import React from 'react'
import { ExtendedInfo } from '@connected-video-web/dstv-frontend-components/dist/Components/Container';
import styles from '../../base-styles/playerui.scss';
import { mainData } from '@connected-video-web/dstv-frontend-services/dist/Utils/spatialNavigation/accountFocusHelper';
import { settings } from '@connected-video-web/dstv-frontend-services/dist/constants/resource'

export const getExtendedInfo = (newState) => {
  return newState.extendedInfo
    && newState.extendedInfo.eventType &&
    <ExtendedInfo {...newState.extendedInfo} />
}

export const getSection = (newState) => {
  let section
  if (newState.sections && newState.sections['data_livetv'].data
    && newState.sections['data_livetv'].data[0].items
    && newState.sections['data_livetv'].data[0].items.length > 0) {

    let sectionData = newState.sections['data_livetv'].data[0].items
    section = sectionData.map((e, i) => {
      return <li key={i} id={sectionData[i].title} className={(e.isActive ? styles.focus : '')} onClick={() => this.onClick(e)}>{e.displayName}</li>
      //onClick={()=>this.scrollToRef(i)} 
    })
  }
  return section;
}

export const getSelectedSection = (data, title) => {
  return data.filter(i => {
    return i.displayName === title
  })
}

export const getActiveSection = (newState) => {
  let section
  if (newState.sections && newState.sections['data_livetv'].data
    && newState.sections['data_livetv'].data[0].items
    && newState.sections['data_livetv'].data[0].items.length > 0) {
    section = newState.sections['data_livetv'].data[0].items.filter(i => {
      return i.isActive
    })
  }
  return section
}

export const checkSectionIsvisible = (newState) => {
  let sectionVisible = false
  if (newState.sections && newState.sections['data_livetv'].data
    && newState.sections['data_livetv'].data[0].items
    && newState.sections['data_livetv'].data[0].items.length > 0) {
    sectionVisible = newState.sections['data_livetv'].data[0].isVisible;
  }
  return sectionVisible
}

export const checkExtendedInfoIsvisible = (newState) => {
  let isVisible = false
  if (newState.extendedInfo) {
    isVisible = newState.extendedInfo.isTvFocus ? newState.extendedInfo.isTvFocus : false
  }
  return isVisible
}


export const checkSectionIsHide = (newState) => {
  let sectionHide = false
  if (newState.sections && newState.sections['data_livetv'].data) {
    sectionHide = newState.sections['data_livetv'].data[0].isHide ? true : false;
  }
  return sectionHide
}

export const getDurationHHMMSS = (secs) => {
  if (secs) {
    var sec_num = parseInt(secs, 10)
    var hours = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return [hours, minutes, seconds]
      .map(v => v < 10 ? "0" + v : v)
      .filter((v, i) => v !== "00" || i >= 0)
      .join(":")
  } else {
    return '00:00:00'
  }
}

export const setPlayerSeason = (season) => {
  return season ? 'S' + season : ''
}

export const setPlayerEpisode = (episode, seasonNumber) => {
  if (seasonNumber) {
    return episode ? ', Ep' + episode : ''
  } else {
    return episode ? 'Ep' + episode : ''
  }
}

export const setPlayerEpisodeTitle = (title, seasonNumber, seasonEpisode) => {
  if ((seasonNumber || seasonEpisode) && title) {
    return ' - ' + title
  } else {
    return title || ''
  }
}

export const findActiveEpisode = (props) => {
  let activeEpisode = props && props.actionPayload && props.actionPayload.episode && props.actionPayload.episode.items.filter(function (e) {
    return e.isTvFocus || e.type === "boxoffice_movies";
  });

  if (activeEpisode && activeEpisode.length > 0) {
    return activeEpisode[0].details;
  } else if (props && props.actionPayload && props.actionPayload.episode && props.actionPayload.episode.items && props.actionPayload.episode.items.length > 0) {
    return props.actionPayload.episode.items[0].details
  }
  return '';
}

export const getAssetTag = (player) => {
  return player.actionPayload &&
    player.actionPayload.payload ? player.actionPayload.payload.channelTag :
    player.actionPayload.episode &&
      player.actionPayload.episode.items && player.actionPayload.episode.items.length &&
      player.actionPayload.episode.items[0].details && player.actionPayload.episode.items[0].details.id ?
      player.actionPayload.episode.items[0].details.id : '';
}

export const getCurrentTime = () => {
  return Math.round((new Date()).getTime() / 1000)
}

export const getVodStates = () => {
  return { isPlaying: true, seekPercentage: '0%', progressSeek: { isTvFocus: false }, playPause: { isTvFocus: false }, settings: { isTvFocus: false}, seekDuration: 0, seeking: false, playbackCompleted: false, cancelAutoPlay: false };
}

export const getContainerStates = () => {
  return { showPlayer: false, playing: false, playerLoaded: false, currentDuration: 0, mediaDuration: 0, isLoading: false, fromError: false, playbackStarted: false, forcePlayPause: true, inActiveHours: 0, canSkip: false, logs: [], playTimestamp: "", currentBitRate: 0, targetBitRate: 0, showBackdrop: false, autoPlayNext: false, timeToShowMessage: 60 }
}

export const getLiveStates = () => {
  return { hoursMode: 24, stopLimit: 4, isPlaying: true, prevIsVisible: true, nextIsVisible: true, settings: { isTvFocus: false },prev: { isTvFocus: false, isVisible: true, channelDetail: '' }, playPause: { isTvFocus: false }, next: { isTvFocus: false, channelDetail: '', isVisible: true }, isVisible: false }
}

export const autoPlayActions = () => {
  return [
    {
      'label': 'Watch',
      isTvFocus: true
    },
    {
      label: 'Cancel',
      isTvFocus: false
    }
  ]
}
