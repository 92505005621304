import React, { Component } from 'react'
import { connect } from 'react-redux'
import { StreamaSettings } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import { getFocusedIndex, focusKey } from '@connected-video-web/dstv-frontend-services/dist/Utils/spatialNavigation/helper';
import { talkToChannel } from '@connected-video-web/streama-system-manager-service/dist/services/talkToChannel';
import { KeyCode } from '../../keyCodeMapping';
import SettingsAccount from './settings/SettingsAccount';
import { checkDeepLinkLogic } from "../helper"
import { STREAMA_SETTINGS_CLEAR, SET_MENU_INDEX, MAIN_COMPONENT_FOCUS, ACCOUNT_INIT, CATCHUP_CLEAR } from '@connected-video-web/dstv-frontend-services/dist/actions'
import {ottSettings } from '@connected-video-web/dstv-frontend-services/dist/constants/resource'
let productType = localStorage.getItem('PRODUCT_TYPE');
class StreamaSetting extends Component {
    constructor(props) {
        super(props)
        this.onSelected = this.onSelected.bind(this);
        this.props.clearCatchup();
    }

    componentWillMount = () =>{
        document.addEventListener("keydown", this.onSelected, false);
        let path = this.props.location.pathname.split('/')
        if (path[1] !== 'profiles' && window.isStreama)
            this.props.getSettingsData()
        if (this.props.menu && this.props.menu.data && this.props.menu.data.length && path[1] !== 'profiles' && window.isStreama) {
            checkDeepLinkLogic(this.props, "settings", "streamasettings")
        }
    }

    componentWillUnmount = () => {
        document.removeEventListener('keydown', this.onSelected);
        window.focusedItem = "";
        this.props.clearSettings();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.streamasettings.data && nextProps.streamasettings.data[0]) {
            window.focusedItem = nextProps.streamasettings.data[0].items.filter(item => item.isTvFocus).pop()
        }
    }

    onClick = (item, listIndex) => {
        if (item.id === 'DStv_Account') {
            this.props.accountInit({onClick: true, item});
            this.props.history.push(`/settings/DStv_Account`);
        }
    }

    onSelected = (e) => {
        switch (KeyCode(e.keyCode)) {
            case 'SELECT':
                if (window.focusedItem && window.focusedItem.SMS) {
                    productType === 'STREAMA' ? talkToChannel(window.focusedItem.SMS): '';
                }
                if (window.isStreama && this.props.streamasettings &&
                    this.props.streamasettings.data &&
                    this.props.streamasettings.data.length &&
                    this.props.streamasettings.data[0].isTvFocus) {
                    let activeItem = this.props.streamasettings.data[0].items.filter(item => item.isTvFocus).pop()
                    if (activeItem.id === "DStv_Account")
                        this.props.history.push(`/settings/${activeItem.id}`)
                }
                break;
            case 'BACK':
            case 'LEFT':
            case 'RIGHT':
            case 'UP':
            case 'DOWN':
                if (window.isStreama && this.props.streamasettings &&
                    this.props.streamasettings.data &&
                    this.props.streamasettings.data.length &&
                    this.props.streamasettings.data[0].isTvFocus) {
                    this.props.history.push(`/settings/`)
                }
                break;
            default:
                break;
        }
    }
    getComponentByPath = (page) => {
        if(!window.isStreama)
            return <SettingsAccount />
        else if (page[page.length - 1] === "DStv_Account")
            return <SettingsAccount />
        else if (this.props.player.manageDeviceError) {
            this.props.history.push(`/settings/DStv_Account`)
            return null
        }
        else {
            return (
                <React.Fragment>
                    <StreamaSettings items={ottSettings.items} isTvFocus={ottSettings.isTvFocus} onClick={this.onClick}/>
                </React.Fragment>
            );
        }
    }
    render() {
        return this.getComponentByPath(this.props.page)
    }
}
/**
 * 
 * @param {*} 
 */
const mapStateToProps = state => {
    return {
        streamasettings: state.streamasettings,
        menu: state.menu,
        player: state.player
    }
};
const mapDispatchToProps = dispatch => ({
    clearSettings: () => dispatch({ type: STREAMA_SETTINGS_CLEAR }),
    clearCatchup: () => dispatch({ type: CATCHUP_CLEAR }),
    getSettingsData: () => dispatch ({type: 'GET_SETTINGS_DATA'}),
    checkMainComponentFocus: (page) => dispatch({type: MAIN_COMPONENT_FOCUS, payload:{key:page}}),
    setMenuIndex:(index) => dispatch({type: SET_MENU_INDEX, payload:{index}}),
    accountInit:(payload) => dispatch({type: ACCOUNT_INIT, payload})
})

export default connect(mapStateToProps, mapDispatchToProps)(StreamaSetting);